<template>
  <div class="date-picker">
    <button
      class="btn prev"
      type="button"
      @click="day ? handleDayChangeArrow(-1) : handleMonthChange(-1)"
      v-if="!active"
    >
      <img
        src="@/assets/icons/Arrow_Square_Right_Small_2x.png"
        alt="Arrow_Square_Right_Small_2x"
      />
    </button>
    <Calendar
      :class="{ active: active }"
      v-model="calendarDate"
      view="month"
      :dateFormat="dateFormat"
      :yearRange="'2020:' + new Date().getFullYear()"
      :maxDate="new Date()"
      :monthNavigator="false"
      :yearNavigator="true"
      :manualInput="false"
      @date-select="handleDateChange"
      @show="setActiveState(true)"
      @hide="setActiveState(false)"
    >
      <template #footer v-if="day">
        <div class="day-picker">
          <button class="btn prev" type="button" @click="handleDayChange(-1)">
            <img
              src="@/assets/icons/Arrow_Square_Right_Small_2x.png"
              alt="Arrow_Square_Right_Small_2x"
            />
          </button>
          <input
            type="number"
            maxlength="2"
            :value="dayFormatted"
            @input="handleDayInput"
          />
          <button
            class="btn next"
            :class="{ disabled: dayArrowDisabled }"
            type="button"
            @click="handleDayChange(1)"
            :disabled="dayArrowDisabled"
          >
            <img
              src="@/assets/icons/Arrow_Square_Right_Small_2x.png"
              alt="Arrow_Square_Right_Small_2x"
            />
          </button>
        </div>
      </template>
    </Calendar>
    <button
      class="btn next"
      :class="{ disabled: mainArrowDisabled }"
      type="button"
      @click="day ? handleDayChangeArrow(1) : handleMonthChange(1)"
      v-if="!active"
    >
      <img
        src="@/assets/icons/Arrow_Square_Right_Small_2x.png"
        alt="Arrow_Square_Right_Small_2x"
      />
    </button>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
import moment from "moment";
import _ from "lodash";

export default {
  name: "DateSelector",
  components: {
    Calendar,
  },
  props: {
    day: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Date,
      default: new Date(),
    },
  },
  data() {
    return {
      calendarDate: this.date,
      active: false,
    };
  },
  watch: {
    date() {
      this.calendarDate = this.date;
    },
  },
  computed: {
    dateFormat() {
      return this.day ? "yy M dd" : "yy M";
    },
    dayFormatted() {
      return moment(this.date).format("DD");
    },
    mainArrowDisabled() {
      return this.day
        ? moment(this.date).format("DD") === moment(new Date()).format("DD")
        : moment(this.date).format("MM") === moment(new Date()).format("MM");
    },
    dayArrowDisabled() {
      return moment(this.date).format("DD") === moment(new Date()).format("DD");
    },
  },
  methods: {
    handleDateChange() {
      this.$emit("date-change", this.calendarDate);
    },
    handleMonthChange(dir) {
      this.$emit("month-change", dir);
    },
    handleDayChangeArrow(dir) {
      this.$emit("one-day-change", dir);
    },
    handleDayChange(dir) {
      this.calendarDate = moment(this.calendarDate).add(dir, "day").toDate();
      this.$emit("day-change", this.calendarDate);
    },
    handleDayInput: _.debounce(function (e) {
      this.calendarDate = moment(this.calendarDate)
        .set("date", Number(e.target.value))
        .toDate();
      this.$emit("day-change", this.calendarDate);
    }, 500),
    setActiveState(state) {
      this.active = state;
    },
  },
};
</script>

<style lang="scss">
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.date-picker {
  display: flex;
  align-items: center;
  width: 226px;
  height: 42px;
  .p-calendar {
    &.active {
      background: $PureWhite;
      outline: none;
      .p-inputtext {
        font-style: italic;
      }
    }
    .p-inputtext {
      text-align: center;
      outline: none;
      border: none;
      text-transform: uppercase;
      box-shadow: none !important;
      background: transparent;
      font-family: "RobotoMono";
      font-size: 18px;
      line-height: 24px;
      color: $PureBlack;
      cursor: pointer;
      padding: 9px 23px;
    }
  }
  .btn {
    // width: 42px;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    &.prev {
      transform: scaleX(-1);
    }
  }
}

.p-datepicker {
  width: 226px !important;
  padding: 0 !important;
  transform: translateX(-26px);
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  .p-datepicker-header {
    border-radius: 0 !important;
    .p-link {
      color: $PureBlack !important;
    }
    .p-datepicker-title {
      border: 5px solid $SoothingBlue;
      width: 142px;
      text-align: center;
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 18px;
        line-height: 24px !important;
        font-family: "RobotoMono";
      }
    }
  }
}

.day-picker {
  display: flex;
  align-items: center;
  height: 42px;
  input {
    height: 100%;
    text-align: center;
    border: 5px solid $SoothingBlue;
    width: 142px;
    font-size: 18px;
    line-height: 24px !important;
    font-family: "RobotoMono";
  }
  .btn {
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    flex-basis: 42px;
    &.prev {
      transform: scaleX(-1);
    }
  }
}

.p-monthpicker-month {
  padding: 9px 23px !important;
  text-transform: uppercase;
  &.p-highlight {
    background: $SoothingBlue !important;
    font-style: italic;
  }
}
</style>
