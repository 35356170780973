<template>
  <section class="account">
    <PageDescriptorPrimary
      title="account"
      content="Your personal information and available applications."
    />
    <PageContent>
      <div class="account-info">
        <Title2 content="Account information" />
        <SummaryCommentary class="info-list">
          <div class="info-item">ID: {{ user.id }}</div>
          <div class="info-item">EMAIL: {{ user.email }}</div>
        </SummaryCommentary>
      </div>
      <div class="applications">
        <Title2 content="Applications" />
        <Toggle
          class="toggle"
          :content="`(${appsList.length}) Applications the user is registered in.`"
          :expanded="appsList.length <= 10"
        >
          <SummaryCommentary>
            <ul class="apps-list">
              <li class="accent" v-for="(item, i) in appsList" :key="item.id">
                <span class="number">{{ i + 1 }}.</span>
                {{ item.name }}
              </li>
            </ul>
          </SummaryCommentary>
        </Toggle>
      </div>
    </PageContent>
  </section>
</template>

<script>
import Title2 from "@/components/constructor/textual/Title2";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import Toggle from "@/components/constructor/textual/Toggle";
import PageContent from "@/components/frame/PageContent";
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import { mapGetters } from "vuex";

export default {
  name: "Account",
  components: {
    Title2,
    SummaryCommentary,
    Toggle,
    PageContent,
    PageDescriptorPrimary,
  },
  computed: {
    ...mapGetters("authorization", ["user"]),
    ...mapGetters("apps", ["appsList"]),
  },
};
</script>

<style style="scss" scoped>
.info-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.number{
  font-weight: 400;
}
.info-item {
  margin-top: 32px;
}

.toggle {
  margin-top: 21px;
}
</style>
