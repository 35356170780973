<template>
  <ul class="radio">
    <li
      class="radio-button"
      :class="{ selected: activeOption === null }"
      v-if="resetOption"
    >
      <input type="radio" :name="name" @change="onChange(null)" />
      <label>None</label>
    </li>
    <li
      class="radio-button"
      :class="{ selected: item.id === activeOption }"
      v-for="(item, i) in options"
      :key="i"
    >
      <input type="radio" :name="name" :value="item" @change="onChange(item)" />
      <label>{{ item.value }}</label>
    </li>
  </ul>
</template>

<script>
export default {
  name: "OptionSwitcher",
  props: {
    options: {
      type: Array,
      required: true,
    },
    activeOption: {
      type: [Number, Object],
      default: null,
    },
    resetOption: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    onChange(item) {
      this.$emit("onChange", item);
    },
    reset() {
      this.onChange(null);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  margin: 0;
  padding: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px 0;
  height: 42px;
  .radio-button {
    list-style: none;
    position: relative;
    border: none;
    outline: 1px solid $SoothingBlue;
    background: transparent;
    transition: background-color 0.5s;
    margin-top: 1px;
    margin-left: 1px;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.selected, &:hover {
      background: $SoothingBlue;
      label {
        color: $PureWhite;
        font-style: italic;
      }
    }
    &:hover:not(.selected)>input{
      cursor: pointer;
    }

    input {
      margin: 0;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
    label {
      display: block;
      padding: 9px 18px;
      color: $PureBlack;
      transition: color 0.5s ease;
      font-size: 18px;
      line-height: 24px;
      transition: color 0.5s;
    }
  }
}
</style>
