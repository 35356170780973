<template>
  <header class="header">
    <div class="logo" @click="toMain">
        <img src="@/assets/logo/FluxCortex_logotypes_2x.png" alt="logo" />
        <sup class="version">Beta</sup>
    </div>
    
    <div class="apps">
      <Toggle class="insideHeader" :content="appName">
        <template #special>
          <span class="user">{{ email?.charAt(0) }}</span>
        </template>
        <div class="applications">
          <Title3 content="applications"></Title3>
          <ul class="apps">
            <ListLink
              class="dropdown-item"
              v-for="app in appsList"
              :title="app.name"
              :key="app.id"
              @click="setCurrentApp(app)"
            />
          </ul>
        </div>
        <div class="account">
          <Title3 content="account"></Title3>
          <ul class="accounts-list">
            <ListLink class="dropdown-item" :title="email" @click="$router.push({name: 'account'})" />
            <ListLink class="dropdown-item" title="Log Out" @click="logout" />
          </ul>
        </div>
      </Toggle>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Toggle from "@/components/constructor/textual/Toggle";
import Title3 from "@/components/constructor/textual/Title3";
import ListLink from "@/components/constructor/textual/ListLink";

export default {
  name: "Header",
  components: {
    Toggle,
    Title3,
    ListLink,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("apps", ["appsList", "currentApp"]),
    appName() {
      return this.currentApp?.name ?? "Select application";
    },
  },
  methods: {
    ...mapActions("apps", ["setCurrentApp"]),
    logout() {
      this.$router.push({ name: "signIn" });
      // localStorage.clear();
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh");
    },
    toMain() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #aaaaaa;
  max-height: 50px;
  position: sticky;
  z-index: 5;
  :deep() .arrow-wrapper {
    width: 50px;
    justify-content: center;
  }
  .logo {
    width: 150px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
  }
  .version {
    height: 100%;
    color: #fff;
    padding: 0.5rem;
    background: #73e500;
    border-radius: 5px;
    font-family: "RobotoMono";
    font-size: 0.7rem;
    font-style: italic;
    margin: 0.5rem;
  }

  .insideHeader {
    height: 100%;
    :deep() .dropbtn {
      justify-content: space-between;
    }
    &.active {
      background-color: $PureWhite;
    }
    :deep() .dropdown-content {
      background-color: $PureWhite;
    }
  }
  .apps {
    width: 437px;
    .text {
      font-family: "RobotoMono";
      font-size: 18px;
      font-style: italic;
      line-height: 24px;
    }
    .user {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 43px;
      text-transform: uppercase;
      color: #3f4bf2;
      background: #fff;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .applications,
    .account {
      .title {
        padding-left: 21px;
      }
      .dropdown-item {
        padding: 0 21px;
        max-height: 45px;
        transition: background-color 0.5s, color 0.5s;
        align-items: center;
        &:hover {
          background: #3f4bf2;
          color: #fff;
          font-style: italic;
        }
      }
    }

    .apps,
    .accounts-list {
      margin-top: 10px;
    }
  }
}
</style>
