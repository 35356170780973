<template>
  <div class="summary">
    <p v-html="str1"></p>
    <p v-html="str2"></p>
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: {
    str1: {
      type: String,
      required: true,
    },
    str2: {
      type: String,
      default:'',
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  height: 76px;
  color: $PureBlack;
  font-size: 16px;
  line-height: 21px;
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > p {
    min-height: 21px;
  }
}
</style>
