<template>
  <div class="sidebar">
    <div class="header">
      <Title class="title" content="Dashboard" />
      <ListLink link="apiUsage" title="API Usage" class="links-item"></ListLink>
    </div>
    <Accordion
      v-for="section in sections"
      :key="section.title"
      :title="section.title"
    >
      <div class="links">
        <ListLink
          v-for="link in section.links"
          :key="link.title"
          :link="link.route"
          :title="link.title"
          class="links-item"
          :class="{ nested: nestedRoute === link.route }"
        ></ListLink>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Title from "@/components/constructor/textual/Title3";
import ListLink from "@/components/constructor/textual/ListLink";
import Accordion from "@/components/simple/Accordion";

export default {
  components: {
    Accordion,
    Title,
    ListLink,
  },
  data() {
    return {
      sections: [
        {
          title: "Configuration",
          links: [
            {
              title: "Characters",
              route: "characters",
            },
            {
              title: "Dialogs",
              route: "dialogs",
            },
            {
              title: "A/B Tests",
              route: "abTests",
            },
            {
              title: "Advanced Settings",
              route: "advancedSettings",
            },
            {
              title: "Commands",
              route: "commands",
            },
          ],
        },
        {
          title: "Data",
          links: [
            {
              title: "Users",
              route: "users",
            },
            {
              title: "Export",
              route: "export",
            },
          ],
        },
        {
          title: "Manage",
          links: [
            {
              title: "Account",
              route: "account",
            },
            {
              title: "Application",
              route: "application",
            },
            {
              title: "Billing",
              route: "billing",
            },
            {
              title: "API Keys",
              route: "apiKeys",
            },
          ],
        },
        {
          title: "API Reference",
          links: [
            {
              title: "Access-Token API",
              route: "accessTokenApi",
            },
          ],
        },
        {
          title: "Resources",
          links: [
             {
              title: "Getting Started",
              route: "gettingStarted",
            },
          ],
        },
      ],
      nestedRoute: null,
    };
  },
  watch: {
    $route() {
      this.nestedRoute = this.$route.meta.nested;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 300px;
  border-right: 1px solid $InactiveGrey;
  padding-bottom: 60px;
}

.header {
  padding-top: 15px;
  .title {
    margin-left: 57px;
  }
  .links-item {
    margin-top: 20px;
    padding-left: 57px;
    max-height: 45px;
    align-items: center;
  }
}

.links {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .links-item {
    padding-left: 57px;
    max-height: 46px;
    align-items: center;
  }
}
</style>
