<template>
  <div class="page-descriptor">
    <Title :content="title" />
    <Summary :str1="content" :str2="content2" />
  </div>
</template>

<script>
import Title from "@/components/constructor/textual/Title1";
import Summary from "@/components/constructor/textual/Summary";

export default {
  name: "PageDescriptorPrimary",
  components: {
    Title,
    Summary,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    content2: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-descriptor {
  height: 177px;
}
</style>
