<template>
  <div class="dropdown" :class="[{ active: isActive }, newClass]">
    <div class="dropdown__header">
      <button class="dropdown__btn" @click="toggle">
        <img
          class="arrow"
          src="@/assets/icons/Arrow_Square_Down_42px_2x.png"
          alt="Arrow_Downwards"
        />
        <Summary>
          <span
            class="text"
            :class="{
              play: activeSelect === 'Active',
              closed: activeSelect === 'Closed',
            }"
            :style="{
              color: !content ? '#AAAAAA' : null,
            }"
            >{{ activeSelect }}</span
          >
        </Summary>
      </button>
    </div>

    <div class="dropdown__content" @click="handleClose">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Summary from "@/components/constructor/textual/SummaryCommentary";

export default {
  name: "OptionsDropdown",
  components: {
    Summary,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    newClass: {
      // dropdown--state
      type: String,
      default: null,
    },
  },
  mounted() {
    document.addEventListener("click", (event) => {
      this.handleOutsideClick(event);
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", (event) => {
      this.handleOutsideClick(event);
    });
  },
  data: () => ({
    isActive: false,
  }),
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    handleClose() {
      this.isActive = false;
    },
    handleOutsideClick(e) {
      if (!this.$el.contains(e.target)) {
        this.isActive = false;
      }
    },
  },
  computed: {
    activeSelect() {
      return this.content ? this.content : this.placeholder;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  min-width: 364px;
  width: 100%;
  .dropdown__btn {
    .text {
      font-style: normal;
    }
  }
  &.dropdown--state {
    min-width: auto;
  }
  .play {
    &::before {
      content: "▶";
      display: inline-block;
      margin-right: 7px;
    }
  }
  .closed {
    &::before {
      content: "x";
      display: inline-block;
      text-transform: lowercase;
      margin-right: 5px;
    }
  }
  &.active {
    .dropdown__header {
      border-color: $PureWhite;
      background-color: $PureWhite;
    }

    .dropdown__content {
      display: block;
      top: 42px;
      left: 0;
    }
    .dropdown__btn {
      .text {
        font-size: 16px;
        line-height: 21px;
        font-style: italic;
      }

      img {
        transform: rotate(180deg);
      }
    }
  }
  &__header {
    cursor: pointer;
    width: 100%;
    border: 5px solid $SoothingBlue;
    background: transparent;
    height: 42px;
  }
  &__btn {
    user-select: none;
    padding: 0;
    color: #000000;
    border: none;

    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    background: transparent;
    width: inherit;
    height: 100%;
    padding-left: 7px;
    .text {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
    .arrow {
      max-width: 42px;
      transition: transform 0.3s;
    }
  }

  &__content {
    background: $PureWhite;
    display: none;
    position: absolute;
    // background-color: #fff;
    width: 100%;
    z-index: 1;
  }
}
</style>
