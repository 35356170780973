import http from "@/api/api";
import { auth } from "@/api/api";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    items: [],
    totalRecords: null,
  },
  getters: {
    items: (state) => state.items,
    totalRecords: (state) => Number(state.totalRecords),
  },
  mutations: {
    setData(state, payload) {
      state.items = payload;
    },
    setTotalRecords(state, payload) {
      state.totalRecords = payload;
    },
  },
  actions: {
    async getData({ commit }, { appId, sort, limit, offset }) {
      try {
        const response = await http.get(`/api/v1/apps/${appId}/tokens`, {
          headers: { ...auth() },
          params: {
            limit,
            offset,
            sort:
              sort && sort?.sortField
                ? `${sort.sortField}_${sort?.sortOrder === 1 ? "asc" : "desc"}`
                : null,
          },
        });
        const total = response.headers["x-total-count"];
        commit("setData", response.data);
        commit("setTotalRecords", total);
        return response;
      } catch (e) {
        console.log(e);
      }
    },
    async createItem(store, { appId, name, webhookUrl }) {
      try {
        const response = await http.post(
          `/api/v1/apps/${appId}/tokens`,
          {
            name,
            webhook_url: webhookUrl,
          },
          { headers: { ...auth() } }
        );
        Notification(descriptions.tokenAdded, types.success);
        return response?.data;
      } catch (e) {
        Notification(e.response?.data?.detail[0]?.msg, types.danger);
      }
    },
    async deleteItem(store, { appId, itemId }) {
      try {
        const response = await http
          .delete(`/api/v1/apps/${appId}/tokens/${itemId}`, {
            headers: { ...auth() },
          })
          .then(() => {
            Notification(descriptions.tokenDeleted, types.success);
          })
          .catch((e) => {
            Notification(e.response?.data?.detail[0]?.msg, types.danger);
          });
        return response;
      } catch (e) {
        console.log("err", e);
        Notification(e.response?.data?.detail[0]?.msg, types.danger);
      }
    },
  },
};
