import { createRouter, createWebHistory } from "vue-router";
import Container from "@/views/Container";
import APIUsage from "@/views/ApiUsage";
import Characters from "@/views/Characters";
import CharactersEdit from "@/views/Characters/Edit";
import Dialogs from "@/views/Dialogs";
import DialogsEdit from "@/views/Dialogs/Edit";
import ABTests from "@/views/AbTests";
import ABTestsEdit from "@/views/AbTests/Edit";
import AdvancedSettings from "@/views/AdvancedSettings";
import Commands from "@/views/Commands";
import AdvancedSettingsEdit from "@/views/AdvancedSettings/Edit";
import CommandsEdit from "@/views/Commands/Edit";
import Account from "@/views/Account";
import Billing from "@/views/Billing";
import Export from "@/views/Export";
import SignIn from "@/views/SignIn";
import Application from "@/views/Application";
import Users from "@/views/Users";
import UsersEdit from "@/views/Users/Edit";
import ApiKeys from "@/views/ApiKeys";
import AccesTokenApi from "@/views/AccesTokenApi";
import GettingStarted from "@/views/GettingStarted";


import E404 from "@/components/simple/E404";

const routes = [
  {
    path: "/signIn",
    name: "signIn",
    component: SignIn,
  },
  {
    path: "",
    component: Container,
    redirect: { name: 'apiUsage' },
    children: [
      {
        path: "apiUsage",
        name: "apiUsage",
        component: APIUsage,
      },
      {
        path: "characters",
        name: "characters",
        component: Characters,
      },
      {
        path: "characters/create",
        name: "charactersCreate",
        component: CharactersEdit,
        meta: { nested: "characters" },
      },
      {
        path: "characters/edit/:id",
        name: "charactersEdit",
        component: CharactersEdit,
        meta: { nested: "characters" },
      },
      {
        path: "/dialogs",
        name: "dialogs",
        component: Dialogs,
      },
      {
        path: "/dialogs/create",
        name: "dialogsCreate",
        component: DialogsEdit,
        meta: { nested: "dialogs" },
      },
      {
        path: "/dialogs/edit/:id",
        name: "dialogsEdit",
        component: DialogsEdit,
        meta: { nested: "dialogs" },
      },
      {
        path: "/tests",
        name: "abTests",
        component: ABTests,
      },
      {
        path: "/tests/create",
        name: "abTestsCreate",
        component: ABTestsEdit,
        meta: { nested: "abTests" },
      },
      {
        path: "/tests/edit/:id",
        name: "abTestsEdit",
        component: ABTestsEdit,
        meta: { nested: "abTests" },
      },
      {
        path: "/advancedSettings",
        name: "advancedSettings",
        component: AdvancedSettings,
      },
      {
        path: "/advancedSettings/create",
        name: "advancedSettingsCreate",
        component: AdvancedSettingsEdit,
        meta: { nested: "advancedSettings" },
      },
      {
        path: "/advancedSettings/edit/:id",
        name: "advancedSettingsEdit",
        component: AdvancedSettingsEdit,
        meta: { nested: "advancedSettings" },
      },
      {
        path: "/commands",
        name: "commands",
        component: Commands,
      },
      {
        path: "/commands/create",
        name: "commandsCreate",
        component: CommandsEdit,
        meta: { nested: "commands" },
      },
      {
        path: "/commands/edit/:id",
        name: "commandsEdit",
        component: CommandsEdit,
        meta: { nested: "commands" },
      },
      {
        path: "/account",
        name: "account",
        component: Account,
      },
      {
        path: "/billing",
        name: "billing",
        component: Billing,
      },
      {
        path: "/export",
        name: "export",
        component: Export,
      },
      {
        path: "application",
        name: "application",
        component: Application,
      },
      {
        path: "users",
        name: "users",
        component: Users,
      },
      {
        path: "users/edit/:id",
        name: "usersEdit",
        component: UsersEdit,
        meta: { nested: "users" },
      },
      {
        path: "apiKeys",
        name: "apiKeys",
        component: ApiKeys,
      },
      {
        path: "accessTokenApi",
        name: "accessTokenApi",
        component: AccesTokenApi,
      },
      {
        path: "gettingStarted",
        name: "gettingStarted",
        component: GettingStarted,
      },
      {
        path: "/:pathMatch(.*)*",
        name: "E404",
        component: E404,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "signIn" && !localStorage.getItem("access_token"))
    next({ name: "signIn" });
  else next();
});

export default router;
