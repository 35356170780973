<template>
  <div class="error">
    <Title content="Error 404" />
    <Summary str1="Can't resolve this error?" str2="Cotact us at:" class="custom" />
    <a href="mailto:support@temporal.games" class="email"
      ><span class="bold">support</span>@temporal.games</a
    >
    <div class="img-wrapper" @click="toMain">
      <img src="@/assets/logo/Icon_128.png" alt="logo" />
    </div>
  </div>
</template>

<script>
import Title from "@/components/constructor/textual/Title1";
import Summary from "@/components/constructor/textual/Summary";
export default {
  name: "E404",
  components: {
    Title,
    Summary,
  },
  methods: {
    toMain() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .custom {
    max-width: 250px;
  }

  .email {
    margin-top: 21px;
    display: block;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    .bold {
      font-weight: 700;
    }
  }

  .img-wrapper {
    margin-top: 60px;
    max-width: 78px;
    max-height: 78px;
    cursor: pointer;
  }
}
</style>
