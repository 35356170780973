<template>
  <h2 class="title">{{ content }}</h2>
</template>

<script>
export default {
  name: "Title3",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 64px;
  color: $SoothingBlue;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.04em;
  font-style: italic;
  text-transform: uppercase;
	display: flex;
	align-items: flex-end;
}
</style>
