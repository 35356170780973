<template>
  <input
    class="field"
    :type="type"
    :name="name"
    :value="value"
    :class="setTheme"
    :placeholder="placeholder"
    v-if="fieldType === 'input'"
    :disabled="disabled"
    @input="onInput"
  />
  <div class="textarea-wrapper" v-else>
    <div class="field textarea">
      <div class="textarea__placeholder" v-if="!value">
        {{ placeholder }}
      </div>
      <div
        :name="name"
        contenteditable="true"
        tabindex="0"
        @input="onInput($event, 'textarea')"
        class="textarea__content"
        v-html="textareaValue"
        :ref="name"
      ></div>
    </div>
    <SummaryCommentary class="limit"
      ><span :class="{ 'limit-bold': (value ? value.length : 0) > maxLenght }"
        >{{ value ? value.length : 0 }} characters.
      </span>
      Max: {{ maxLenght }}.</SummaryCommentary
    >
  </div>
</template>

<script>
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import _ from "lodash";

export default {
  name: "ValueField",
  emits: {
    input: null,
  },
  components: {
    SummaryCommentary,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    maxLenght: {
      type: Number,
      default: 200,
    },
    fieldType: {
      type: String,
      default: "input",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.textareaValue = this.value;
  },
  data() {
    return {
      textareaValue: null,
    };
  },
  watch: {
    value() {
      if (!this.textareaValue && this.value && this.fieldType === "textarea") {
        const strLimit = this.value?.substring(0, this.maxLenght);
        let str = this.value?.substring(this.maxLenght);
        str = `<span class='highlight'>${str}</span>`;
        this.textareaValue = strLimit + str;
        this.$nextTick(() => {
          this.$refs[`${this.name}`]?.focus();
          document.execCommand("selectAll", false, null);
          document.getSelection().collapseToEnd();
        });
      }
    },
  },
  methods: {
    onInput(e, type) {
      if (type === "textarea") {
        this.$emit(
          "input",
          e.target.innerText === "\n" ? "" : e.target.innerText
        );
        this.handleTextareaInput();
      } else {
        this.$emit("input", e.target.value);
      }
    },
    handleTextareaInput: _.debounce(function () {
      const sel = window.getSelection()
      this.caretPosition = sel.anchorNode.parentElement?.nodeName == "SPAN" ? sel.anchorOffset + parseInt(this.maxLenght) : sel.anchorOffset
      const range = document.createRange()
      const el = this.$refs[`${this.name}`]
      if (this.value) {
        if (this.value.length > this.maxLenght){
          const strLimit = this.value?.substring(0, this.maxLenght);
          let str = this.value?.substring(this.maxLenght);
          str = `<span class='highlight'>${str}</span>`;
          this.textareaValue = strLimit + str;
          this.$nextTick(() => {
            this.$refs[`${this.name}`].focus();
            document.execCommand("selectAll", false, null);
            document.getSelection().collapseToEnd();
          });
        }
        
      } else {
        this.textareaValue = null;
      }
    }, 100),
  },
  computed: {
    setTheme() {
      return this.disabled ? "light" : "dark";
    },
  },
};
</script>

<style lang="scss" scoped>
.limit {
  justify-content: right;
}

input:disabled {
  font-style: italic;
}

:deep().limit-bold {
  color: $Red;
  font-weight: 700;
}
:deep().highlight {
  color: $Red;
  text-transform: none !important;
}
.field {
  min-width: 364px;
  min-height: 42px;
  padding: 4px 7px;
  border: 5px solid $SoothingBlue;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  // text-transform: capitalize;
  font-style: normal;
  &::placeholder {
    color: $InactiveGrey;
    font-style: italic;
  }
  // &:focus,
  // &:active {
  //   background: transparent;
  // }
  &.dark {
    background: transparent;
    color: $PureBlack;
  }
  &.light {
    background: $SoothingBlue;
    color: $PureWhite;
  }
}
textarea {
  &.field {
    resize: none;
    height: 49px;
    padding: 4px 7px;
  }
}
.textarea {
  position: relative;
  color: $InactiveGrey;
  display: block;
  overflow: auto;
  &.field {
    height: 49px;
    padding: 4px 7px;
  }
  &__placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 7px;
    z-index: 0;
  }
  &__content {
    display: inline-block;
    white-space: pre;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    color: $PureBlack;
    > span {
      color: red;
    }
  }
}
</style>
