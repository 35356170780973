import { createStore } from "vuex";

import authorization from "./modules/authorization";
import characters from "./modules/characters";
import apps from "./modules/apps";
import dialogs from "./modules/dialogs";
import tests from "./modules/abTests";
import advancedSettings from "./modules/advancedSettings";
import commands from "./modules/commands";
import apiUsage from "./modules/apiUsage";
import billing from "./modules/billing";
import users from "./modules/users";
import apiKeys from "./modules/apiKeys";

export default createStore({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    authorization,
    characters,
    apps,
    dialogs,
    advancedSettings,
    commands,
    tests,
    apiUsage,
    billing,
    users,
    apiKeys,
  },
});
