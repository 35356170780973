import http from "@/api/api";
import { auth } from "@/api/api";

export default {
  namespaced: true,
  state: {
    balanceInfo: null,
    tariff: null,
  },
  getters: {
		balanceInfo (state) {
      return {
        balance: state.balanceInfo?.balance,
        credit: state.balanceInfo?.credit.toFixed(2)
      }
    },
		tariff: (state) => state.tariff,
	},
  mutations: {
    setData(state, payload) {
      state.balanceInfo = payload.balanceInfo
      state.tariff = payload.tariff
    },
  },
  actions: {
    async getData({ commit }, id) {
      const response1 = await http.get(
        `/api/v1/billing/${id}/balance`,
        {
          headers: { ...auth() },
        }
      );
      const response2 = await http.get(
        `/api/v1/billing/${id}/tariff`,
        {
          headers: { ...auth() },
        }
      );

      commit("setData", {
        balanceInfo: response1?.data,
        tariff: response2?.data,
      });
    },
  },
};
