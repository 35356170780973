import http from "@/api/api";
import { auth } from "@/api/api";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    days: [],
    details: [],
  },
  getters: {
    details(state) {
      return state.details.map((item) => {
        return {
          uuid: (Math.random() + 1).toString(36).substring(7),
          date: moment(item.date).format("M/DD/yyyy hh:mm:ss A"),
          is_free: item.is_free,
          method: item.method,
          price: item.price,
          user_id: item.user_id,
        };
      });
    },
    xAxis(state) {
      return state.days.map((item) => {
        return moment(item.date).format("MMM DD");
      });
    },
    chartData(state) {
      return {
        price: state.days.map((item) => item.price),
        promoPrice: state.days.map((item) => item.promo_price),
      };
    },
    cumulative(state) {
      let price = [];
      let promoPrice = [];

      state.days.reduce((accumulator, item) => {
        price.push(accumulator + item.price);
        return accumulator + item.price;
      }, 0);

      state.days.reduce((accumulator, item) => {
        promoPrice.push(accumulator + item.promo_price);
        return accumulator + item.promo_price;
      }, 0);

      return {
        price: price,
        promoPrice: promoPrice,
      };
    },
  },
  mutations: {
    getData(state, payload) {
      state.days = payload.days;
      state.details = payload.details;
    },
  },
  actions: {
    getData(store, { appId, type, month }) {
      return new Promise((resolve) => {
        http
          .get(`/api/v1/apps/${appId}/api-usage?type=${type}&month=${month}`, {
            headers: { ...auth() },
          })
          .then((response) => {
            store.commit("getData", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            console.log("err", error.response);
          });
      });
    },
  },
};
