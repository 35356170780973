<template>
  <h2 class="title">{{ content }}</h2>
</template>

<script>
export default {
  name: "Title1",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 101px;
  vertical-align: bottom;
  color: $SoothingBlue;
  font-size: 45px;
  line-height: 59px;
  letter-spacing: 0.02em;
  font-style: italic;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
}
</style>
