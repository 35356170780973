<template>
  <button class="actionBtn">{{ content }}</button>
</template>

<script>
export default {
  name: "ActionCallButton",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.actionBtn {
  height: 42px;
  font-size: 18px;
  line-height: 24px;
  background: transparent;
  text-transform: capitalize;
  color: $AccentuatingMagenta;
  cursor: pointer;
  transition: color 0.4s;
	position: relative;
  &::after {
    content: "";
    width: 0;
    height: 100%;
    border-bottom: 1px solid currentColor;
    position: absolute;
    left: 0;
    bottom: 10px;
    transition: width 0.4s;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
  &:hover {
    color: #ce14b2;
  }
  &:disabled {
    pointer-events: none;
    font-style: italic;
    color: $PureBlack;
  }
}
</style>
