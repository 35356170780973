import http from "@/api/api";
import { auth } from "@/api/api";
import router from "@/router";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    items: [],
    currentItem: {
      id: null,
      content: null,
      template: null,
      abId: null,
    },
    totalRecords: null,
  },
  getters: {
    items(state) {
      return state.items;
    },
    currentItem(state) {
      return state.currentItem;
    },
    totalRecords: (state) => Number(state.totalRecords),
  },
  mutations: {
    resetCurrentItem(state) {
      state.currentItem.id = null;
      state.currentItem.content = null;
      state.currentItem.template = null;
    },
    getItems(state, payload) {
      state.items = payload;
    },
    getItem(state, payload) {
      state.currentItem = payload;
    },
    updateCurrentItem(state, { field, value }) {
      state.currentItem[field.toLowerCase()] = value;
    },
    getTotalRecords(state, payload) {
      state.totalRecords = payload;
    },
  },
  actions: {
    getItems(store, { appId, abId, limit, offset, sort }) {
      http
        .get(`/api/v1/dialogue/${appId}/commands`, {
          headers: { ...auth() },
          params: {
            limit,
            offset,
            ab_id: abId,
            sort:
              sort && sort?.sortField
                ? `${sort.sortField}_${sort?.sortOrder === 1 ? "asc" : "desc"}`
                : null,
          },
        })
        .then((response) => {
          const payload = response.data;
          const total = response.headers["x-total-count"];
          store.commit("getItems", payload);
          store.commit("getTotalRecords", total);
        })
        .catch((error) => {
          console.log("err", error);
        });
    },
    async getItem(store, { appId, itemId, abId }) {
      const response = await http.get(
        `/api/v1/dialogue/${appId}/commands/${itemId}${
          abId ? `/${abId}` : ""
        }`,
        {
          headers: { ...auth() },
        }
      );
      const payload = response.data;
      store.commit("getItem", payload);
      return payload;
    },
    createItem(store, { appId, itemId, abId, content, template }) {
      http
        .post(
          `/api/v1/dialogue/${appId}/commands` +
            `${itemId ? "/" + itemId + "/ab" : ""}`,
          {
            content: content,
            template: template,
            ab_id: abId,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "commands" });
          Notification(descriptions.commandCreated, types.success);
        })
        .catch((error) => {
          Notification(descriptions.danger, types.danger);
        });
    },
    editItem(store, { appId, itemId, content, abId, template}) {
      http
        .patch(
          `/api/v1/dialogue/${appId}/commands/${itemId}` +
            `${abId ? `/${abId}` : ""}`,
          {
            content: content,
            template: template,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "commands" });
          Notification(descriptions.commandUpdated, types.success);
        })
        .catch((error) => {
          Notification(descriptions.danger, types.danger);
        });
    },
    async deleteItem(store, { appId, itemId, abId }) {
      try {
        const response = await http.delete(
          `/api/v1/dialogue/${appId}/commands/${itemId}` +
            `${abId ? `/${abId}` : ""}`,
          {
            headers: { ...auth() },
          }
        );
        Notification(descriptions.commandDeleted, types.success);
        return;
      } catch (e) {
        console.log("err", e);
        Notification(descriptions.danger, types.danger);
      }
    },
    updateCurrentItem(store, payload) {
      store.commit("updateCurrentItem", payload);
    },
    resetCurrentItem(store) {
      store.commit("resetCurrentItem");
    },
  },
};
