<template>
  <PageDescriptorPrimary
    title="Characters"
    content="A list of all created and configured character profiles."
    content2="Create new, edit, and remove existing characters."
  />
  <PageContent class="page-content">
    <div class="page-content__title">
      <Title2 content="Characters List" />
    </div>
    <div class="page-content__str">
      <OptionsSwitcher
        :options="optionsRows"
        :activeOption="activeRows.id"
        @onChange="handleChangeRows"
        name="rows"
      />
      <div class="page-content__str-group">
        <SummaryCommentary class="page-content__str-text">
          A/B:
        </SummaryCommentary>
        <OptionsSwitcher
          :options="tests"
          :activeOption="activeFilterId"
          @onChange="handleChangeFilter"
          name="options"
          resetOption
        />
      </div>
    </div>
    <div class="page-content__str">
      <ActionCallButton @click="handleCreate" content="+ Add character" />
      <div class="page-content__str-group">
        <SummaryCommentary class="page-content__str-text">
          Filter by NAME:
        </SummaryCommentary>
        <ValueField
          name="filterNames"
          placeholder="Name"
          fieldType="input"
          @input="handleSearch"
        />
      </div>
    </div>
    <Table
      :tableData="items"
      :tableColumns="columns"
      :loading="loading"
      :totalRecords="totalRecords"
      :activeRows="activeRows.value"
      :page="currentPage"
      newClass="table--voice"
      @onEdit="handleEdit"
      @onRemove="confirm"
      @onSorting="sort"
      @onPageChange="handlePageChange"
      @play-audio="handleAudioPlay"
    />
    <div class="page-content__title">
      <Title2 content="Edit Character by ID" class="edit-by" />
    </div>
    <div class="page-content__input">
      <ValueField
        name="CharacterId"
        placeholder="Character ID"
        fieldType="input"
        @input="handleEditInput"
      />
    </div>
    <div class="page-content__button">
      <Button
        content="EDIT CHARACTER"
        @click="handleEditById(editId)"
        :disabled="!editId || editId.length === 0"
      />
    </div>
  </PageContent>

  <Modal
    :display="displayModal"
    title="Remove?"
    content="You sure that you want to remove chosen element?"
    btn="YES, REMOVE"
    @onClose="closeModal"
    @onConfirm="handleDelete"
  />
</template>

<script>
import ActionCallButton from "@/components/constructor/graphical/ActionCallButton";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import Table from "@/components/constructor/graphical/Table";
import Modal from "@/components/frame/Popup";
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import { mapGetters, mapActions } from "vuex";
import Title2 from "@/components/constructor/textual/Title2";
import ValueField from "@/components/constructor/graphical/ValueField";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import Button from "@/components/constructor/graphical/Button";
import _ from "lodash";
import http from "@/api/api";
import { auth } from "@/api/api";
import Notification from "@/helpers/Notifications";
import { types, descriptions } from "@/helpers/Notifications";

export default {
  components: {
    Title2,
    ValueField,
    SummaryCommentary,
    Button,
    Table,
    Modal,
    ActionCallButton,
    OptionsSwitcher,
    PageDescriptorPrimary,
    PageContent,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      word: null,
      appId: localStorage.getItem("appId"),
      columns: [
        { field: "id", header: "ID" },
        { field: "name", header: "Name", sortable: true },
        { field: "voice.name", header: "Voice" },
      ],
      displayModal: false,
      currentRowId: null,
      loading: false,
      tests: null,
      sortObj: {
        sortField: "created_at",
        sortOrder: -1,
      },
      optionsRows: [
        {
          id: 0,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
        {
          id: 2,
          value: 30,
        },
      ],
      activeRows: {
        id: 0,
        value: 10,
      },
      currentPage: 0,
      editId: null,
      activeFilter: null,
      searchValue: null,
      audio: null,
    };
  },
  methods: {
    ...mapActions("characters", ["getItems", "deleteItem"]),
    ...mapActions("tests", { getFilters: "getItems" }),
    getData() {
      this.loading = true;
      this.getItems({
        appId: this.appId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: 0,
      }).finally(() => {
        this.loading = false;
      });
      this.getFilters({ appId: localStorage.getItem("appId") }).then(
        (response) => {
          let filteredArr = response.filter((item) => {
            return item.state !== "Closed";
          });

          this.tests = filteredArr.map((item) => {
            return {
              id: item.id,
              value: item.name,
              state: item.state,
              type: item.type,
            };
          });
        }
      );
    },
    handleCreate() {
      this.$router.push({ name: "charactersCreate" });
    },
    handleEdit(data) {
      const { id, ab_id } = data;
      this.$router.push({
        name: "charactersEdit",
        params: {
          id: id,
          abId: this.activeFilterId,
          hasAbId: ab_id && ab_id === this.activeFilterId,
        },
      });
    },
    handleEditInput(val) {
      this.editId = val;
    },
    handleEditById(id) {
      this.$router.push({
        name: "charactersEdit",
        params: {
          id,
          abId: this.activeFilterId,
        },
      });
    },
    confirm(id) {
      this.currentRowId = id;
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    handleDelete() {
      this.loading = true;
      const { id, ab_id } = this.currentRowId;
      this.closeModal();
      this.deleteItem({ appId: this.appId, itemId: id, abId: ab_id }).then(
        () => {
          this.getItems({
            appId: this.appId,
            abId: this.activeFilter,
            sort: this.sortObj,
            limit: this.activeRows.value,
            offset: this.currentPage * this.activeRows.value,
            query: this.searchValue,
          }).finally(() => {
            this.loading = false;
          });
        }
      );
    },
    sort(data) {
      this.loading = true;
      this.sortObj = data;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: data,
        query: this.searchValue,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      }).finally(() => {
        this.loading = false;
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loading = true;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeFilter(data) {
      this.activeFilter = data;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilterId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      });
    },
    handleChangeRows(data) {
      const prev = this.activeRows.value;
      this.activeRows = data;

      this.currentPage =
        Math.ceil((prev * this.currentPage + 1) / this.activeRows.value) - 1;
      this.loading = true;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSearch: _.debounce(function (query) {
      this.searchValue = query;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query,
      }).finally(() => {
        this.loading = false;
      });
    }, 1000),
    async handleAudioPlay(data) {
      const response = await http.post(
        `/api/v1/dialogue/${this.appId}/pronounce`,
        {
          voice_id: data.id,
          text: `Hi, my name is ${data.name}.`,
          tts_processing_mode: "Single",
          tts_result_format: "Raw",
          tts_result_extension: "wav",
        },
        {
          headers: { ...auth() },
        }
      );

      if (response?.data) {
        this.loading = true;

        const timerId = setInterval(async () => {
          try {
            const res = await http.post(
              `/api/v1/dialogue/pronounce/result`,
              {
                pronounce_id: response?.data?.pronounce_id,
              },
              {
                headers: { ...auth() },
              }
            );

            if (res?.status === 200) {
              this.loading = false;
              this.audio = res?.data?.voice;
              let snd = new Audio("data:audio/wav;base64," + this.audio);
              snd.play();
              clearInterval(timerId);
              return;
            }
          } catch (e) {
            Notification(descriptions.danger, types.danger);
            this.loading = false;
            clearInterval(timerId);
            return;
          }
        }, 3000);
      }
    },
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapGetters("characters", ["items", "totalRecords"]),
    activeFilterId() {
      return this.activeFilter ? this.activeFilter.id : null;
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp.id;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title2 {
  margin-bottom: 20px;
}
.edit-by {
  text-transform: none;
}
.page-content {
  &__title {
    margin-bottom: 21px;
  }
  &__str {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & + & {
      margin-top: 21px;
    }
    &-group {
      display: flex;
      align-items: center;
    }
    &-text {
      margin-right: 12px;
    }
  }

  &__input {
    .field {
      width: 100%;
    }
  }
  &__button {
    margin-top: 12px;
  }
}
</style>
