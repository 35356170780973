<template>
  <div class="progress-wrapper" :class="{ separate: separate }">
    <Summary v-if="info">
      {{ infoString }}
    </Summary>
    <div class="progress">
      <div class="progress-line" :style="progress"></div>
    </div>
  </div>
</template>

<script>
import Summary from "@/components/constructor/textual/SummaryCommentary";

export default {
  name: "ProgressBar",
  components: {
    Summary,
  },
  props: {
    info: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    separate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progress() {
      const width = (this.value / this.total) * 100;
      return {
        width: (width <= 0 ? 0 : width) + "%",
      };
    },
    infoString() {
      return `${this.info} ${this.value <= 0 ? 0 : this.value} / ${this.total}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-wrapper {
  display: flex;
  align-items: center;
  gap: 90px;

  &.separate {
    flex-direction: column;
    align-items: flex-start;
    gap: unset;
    .progress {
      flex: unset;
      width: 100%;
    }
  }

  .progress {
    background: $InactiveGrey;
    height: 17px;
    flex: 1;
    .progress-line {
      background: $AccentuatingMagenta;
      height: 100%;
      transition: width 1s ease-in;
    }
  }
}
</style>
