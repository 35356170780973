import http from "@/api/api";
import { auth } from "@/api/api";
import router from "@/router";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    items: [],
    currentItem: {
      id: null,
      name: null,
      persona: null,
      dating: null,
      voiceId: {},
      is_romantic_enable: false,
      dialogs: [],
      abId: null,
      created_at: null,
    },
    voices: [],
    dialogues: [],
    totalRecords: null,
  },
  getters: {
    items(state) {
      return state.items;
    },
    currentItem(state) {
      return state.currentItem;
    },
    voices(state) {
      return state.voices;
    },
    dialogues(state) {
      return state.dialogues;
    },
    attachedDialogues(state) {
      return state.currentItem.dialogs;
    },
    radio(state) {
      if (state.currentItem.is_romantic_enable) {
        return [
          { label: "Enabled", selected: true },
          { label: "Disabled", selected: false },
        ];
      } else {
        return [
          { label: "Enabled", selected: false },
          { label: "Disabled", selected: true },
        ];
      }
    },
    totalRecords: (state) => Number(state.totalRecords),
  },
  mutations: {
    clearVoices(state) {
      state.voices = [];
    },
    resetCurrentItem(state) {
      state.currentItem.id = null;
      state.currentItem.name = null;
      state.currentItem.persona = null;
      state.currentItem.dating = null;
      state.currentItem.voiceId = null;
      state.currentItem.dialogs = [];
      state.currentItem.abId = null;
    },
    getItems(state, payload) {
      state.items = payload;
    },
    getVoices(state, payload) {
      state.voices = payload.map((item) => {
        return {
          label: `${item.name} by ${item.provider}`,
          value: item.id,
        };
      });
    },
    getCurrentItem(state, payload) {
      state.currentItem = {
        id: payload.id,
        name: payload.name,
        persona: payload.persona,
        dating: payload.dating,
        voiceId: payload.voice.id,
        is_romantic_enable: payload.is_romantic_enable,
        dialogs: payload.dialogs_id,
        abId: payload.ab_id,
        created_at: payload.created_at,
      };
    },
    updateCurrentItem(state, { field, value }) {
      state.currentItem[field] = value;
    },
    getDialogs(state, payload) {
      state.dialogues = payload.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    getTotalRecords(state, payload) {
      state.totalRecords = Number(payload);
    },
  },
  actions: {
    getItems(store, { appId, abId, sort, limit, offset, query }) {
      return new Promise((resolve) => {
        http
          .get(`/api/v1/dialogue/${appId}/characters`, {
            headers: { ...auth() },
            params: {
              limit,
              offset,
              ab_id: abId,
              sort:
                sort && sort?.sortField
                  ? `${sort.sortField}_${
                      sort?.sortOrder === 1 ? "asc" : "desc"
                    }`
                  : null,
              name: query,
            },
          })
          .then((response) => {
            const payload = response.data;
            const total = response.headers["x-total-count"];
            store.commit("getItems", payload);
            store.commit("getTotalRecords", total);
            resolve(payload);
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
    },
    async getVoices(store) {
      try {
        const response = await http.get(`/api/v1/dialogue/voices`, {
          headers: { ...auth() },
        });
        store.commit("getVoices", response.data);
        return response.data;
      } catch (e) {
        console.log("err", e);
      }
    },
    getCurrentItem(store, { appId, itemId, abId }) {
      return new Promise((resolve) => {
        http
          .get(
            `/api/v1/dialogue/${appId}/characters/${itemId}${
              abId ? `/${abId}` : ""
            }`,
            {
              headers: { ...auth() },
            }
          )
          .then((response) => {
            const payload = response.data;
            store.commit("getCurrentItem", payload);
            resolve(payload);
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
    },
    async getDialogs(store, appId) {
      try {
        const response = await http.get(`/api/v1/dialogue/${appId}/dialogs?limit=100`, {
          headers: { ...auth() },
        });
        store.commit("getDialogs", response.data);
        return response.data;
      } catch (e) {
        console.log("err", e);
      }
    },
    createItem(
      store,
      {
        appId,
        itemId,
        abId,
        name,
        persona,
        dating,
        voiceId,
        isRomanticEnable,
        dialogs,
      }
    ) {
      http
        .post(
          `/api/v1/dialogue/${appId}/characters` +
            `${itemId ? "/" + itemId + "/ab" : ""}`,
          {
            name: name,
            persona: persona,
            dating: dating,
            voice_id: voiceId,
            is_romantic_enable: isRomanticEnable,
            dialogs_id: dialogs,
            ab_id: abId,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "characters" });
          Notification(descriptions.characterCreated, types.success);
        })
        .catch((error) => {
          Notification(descriptions.danger, types.danger);
        });
    },
    editItem(
      store,
      {
        appId,
        itemId,
        name,
        persona,
        dating,
        voiceId,
        isRomanticEnable,
        dialogs,
        abId,
      }
    ) {
      http
        .patch(
          `/api/v1/dialogue/${appId}/characters/${itemId}` +
            `${abId ? `/${abId}` : ""}`,
          {
            name: name,
            persona: persona,
            dating: dating,
            voice_id: voiceId,
            is_romantic_enable: isRomanticEnable,
            dialogs_id: dialogs,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "characters" });
          Notification(descriptions.characterUpdated, types.success);
        })
        .catch((error) => {
          Notification(descriptions.danger, types.danger);
        });
    },
    async deleteItem(store, { appId, itemId, abId }) {
      try {
        const response = await http.delete(
          `/api/v1/dialogue/${appId}/characters/${itemId}` +
            `${abId ? `/${abId}` : ""}`,
          {
            headers: { ...auth() },
          }
        );
        Notification(descriptions.characterDeleted, types.success);
        return;
      } catch (e) {
        console.log("err", e);
        Notification(descriptions.danger, types.danger);
      }
    },
    updateCurrentItem(store, payload) {
      store.commit("updateCurrentItem", payload);
    },
    resetCurrentItem(store) {
      store.commit("resetCurrentItem");
    },
  },
};
