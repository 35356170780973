<template>
  <section class="billing">
    <PageDescriptorPrimary
      title="Billing"
      content="Your billing and balance information."
    />
    <PageContent>
      <Title2 content="Balance, US$" />
      <Title1 :content="balanceInfo.balance" v-if="balanceInfo" />
      <div class="tariff-table" v-if="tariff">
        <Title2 content="Tariff Pricing, US$" />
        <div class="wrapper">
          <div class="tariff-item">
            <Title1 :content="tariff.ai_price" />
            <Title3 content="AI" />
            <SummaryCommentary class="info">
              <span>Per request</span>
            </SummaryCommentary>
            <SummaryCommentary class="info requests">
              <span>First 100k request/month are $0</span>
            </SummaryCommentary>
          </div>
          <div class="tariff-item">
            <Title1 :content="tariff.tts_price" />
            <Title3 content="TTS" />
            <SummaryCommentary class="info">
              <span>Per 100 character block</span>
            </SummaryCommentary>
          </div>
          <div class="tariff-item">
            <Title1 :content="tariff.storage_price" />
            <Title3 content="Storage" />
            <SummaryCommentary class="info">
              <span>Per user per month</span>
            </SummaryCommentary>
          </div>
        </div>
      </div>
    </PageContent>
  </section>
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import Title2 from "@/components/constructor/textual/Title2";
import Title1 from "@/components/constructor/textual/Title1";
import Title3 from "@/components/constructor/textual/Title3";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Billing",
  components: {
    PageDescriptorPrimary,
    PageContent,
    Title1,
    Title2,
    Title3,
    SummaryCommentary,
  },
  created() {
    if (this.currentApp) {
      this.getData(this.currentApp?.id);
    }
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapGetters("billing", ["balanceInfo", "tariff"]),
  },
  methods: {
    ...mapActions("billing", ["getData"]),
  },
  watch: {
    currentApp() {
      this.getData(this.currentApp?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.billing {
  .tariff-table {
    .wrapper {
      .tariff-item {
        flex: 1 1;
        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          &.requests{
            span{
              text-transform: none;
            }
          }
          span {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
</style>
