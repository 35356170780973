import router from "@/router";
import http from "@/api/api";
import { auth } from "@/api/api.js";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    user: {
      id: null,
      email: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    email(state) {
      return state.user.email;
    },
  },
  mutations: {
    getUser(state, { id, email }) {
      state.user.id = id;
      state.user.email = email;
    },
  },
  actions: {
    login(store, { email, password }) {
      http
        .post("/api/v1/auth/signin", {
          email: email,
          password: password,
        })
        .then((response) => {
          const data = response.data;

          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh", data.refresh_token);

          router.push({ name: "apiUsage" });
        })
        .catch((error) => {
          if (error?.response?.data?.detail === "User not found.") {
            Notification(descriptions.userNotFound, types.danger);
          } else if (error?.response?.data?.detail === "Invalid password.") {
            Notification(descriptions.invalidPassword, types.danger);
          }
        });
    },
    getUser(store) {
      http
        .get("/api/v1/auth/me", { headers: { ...auth() } })
        .then((response) => {
          const payload = response.data;
          store.commit("getUser", payload);
        })
        .catch((error) => {
          console.log("err", error);
        });
    },
  },
};
