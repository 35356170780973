<template>
  <div class="page-descriptor">
    <div class="page-descriptor-header">
      <Title :content="title" />
      <button class="img-wrapper" @click="handleClick">
        <img src="@/assets/icons/Cross_58px_2x.png" alt="Cross_58px" />
      </button>
    </div>
    <Summary :str1="content" :str2="content2" />
  </div>
</template>

<script>
import Title from "@/components/constructor/textual/Title1";
import Summary from "@/components/constructor/textual/Summary";

export default {
  name: "PageDescriptorSubPage",
  components: {
    Title,
    Summary,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    content2: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-descriptor {
  height: 177px;
  .page-descriptor-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .img-wrapper {
      width: 58px;
      height: 58px;
      border: none;
      outline: none;
      cursor: pointer;
      background: transparent;
    }
  }
}
</style>
