<template>
  <PageDescriptorPrimary
    title="USERS"
    content="View and edit the list of all users registered in the App."
  />
  <PageContent class="page-content">
    <div class="page-content__title">
      <Title2 content="Users List" />
    </div>
    <div class="page-content__str">
      <OptionsSwitcher
        :options="optionsRows"
        :activeOption="activeRows.id"
        @onChange="handleChangeRows"
        name="rows"
      />
      <div class="page-content__str-group">
        <SummaryCommentary class="page-content__str-text">
          Filter by NAME:
        </SummaryCommentary>
        <ValueField
          theme="light"
          name="filterNames"
          placeholder="Name"
          fieldType="input"
          @input="handleSearch"
        />
      </div>
    </div>
    <Table
      :tableData="items"
      :tableColumns="columns"
      :loading="loading"
      :totalRecords="totalRecords"
      :activeRows="activeRows.value"
      :page="currentPage"
      paginationItems="users"
      actions="edit"
      @onEdit="handleEdit"
      @onRemove="confirm"
      @onSorting="sort"
      @onPageChange="handlePageChange"
    />
    <div class="page-content__title">
      <Title2 content="Edit User by ID" class="edit-by" />
    </div>
    <div class="page-content__input">
      <ValueField
        theme="light"
        name="UserId"
        placeholder="User ID"
        fieldType="input"
        @input="handleEditInput"
      />
    </div>
    <div class="page-content__button">
      <Button
        content="EDIT USER"
        @click="handleEditById(editId)"
        :disabled="!editId || editId.length === 0"
      />
    </div>
  </PageContent>
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import Title2 from "@/components/constructor/textual/Title2";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import ValueField from "@/components/constructor/graphical/ValueField";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import Table from "@/components/constructor/graphical/Table";
import Button from "@/components/constructor/graphical/Button";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Users",
  components: {
    PageDescriptorPrimary,
    OptionsSwitcher,
    Title2,
    ValueField,
    Button,
    SummaryCommentary,
    PageContent,
    Table,
  },
  created() {
    this.getItems({
      appId: this.appId,
      limit: this.activeRows.value,
      offset: this.currentPage * this.activeRows.value,
    });
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      columns: [
        { field: "id", header: "ID" },
        { field: "external_id", header: "External Id" },
        { field: "name", header: "Name", sortable: true },
        { field: "gender", header: "Gender" },
      ],
      currentRowId: null,
      loading: false,
      sortObj: {
        sortField: "created_at",
        sortOrder: -1,
      },
      optionsRows: [
        {
          id: 0,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
        {
          id: 2,
          value: 30,
        },
      ],
      activeRows: {
        id: 0,
        value: 10,
      },
      currentPage: 0,
      activeFilter: null,
      searchValue: null,
      editId: null,
    };
  },
  methods: {
    ...mapActions("users", ["getItems"]),
    handleEdit(data) {
      const { id, ab_id } = data;
      this.$router.push({
        name: "usersEdit",
        params: {
          id: id,
          abId: this.activeFilter,
          hasAbId: ab_id && ab_id === this.activeFilter,
        },
      });
    },
    handleEditInput(val) {
      this.editId = val;
    },
    handleEditById(id) {
      this.$router.push({
        name: "usersEdit",
        params: {
          id,
        },
      });
    },
    confirm(id) {
      this.currentRowId = id;
    },
    sort(data) {
      this.loading = true;
      this.sortObj = data;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: data,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeFilter(data) {
      this.activeFilter = data;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilterId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loading = true;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeRows(data) {
      const prev = this.activeRows.value;
      this.activeRows = data;

      this.currentPage = Math.ceil(
        (prev * this.currentPage + 1) / this.activeRows.value
      ) - 1;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSearch: _.debounce(function (query) {
      this.searchValue = query;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query,
      }).finally(() => {
        this.loading = false;
      });
    }, 1000),
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapGetters("users", ["items", "totalRecords"]),
    activeFilterId() {
      return this.activeFilter ? this.activeFilter.id : null;
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp.id;
      this.getItems({
        appId: this.appId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title2 {
  margin-bottom: 20px;
}
.edit-by {
  text-transform: none;
}
.page-content {
  &__title {
    margin-bottom: 21px;
  }
  &__str {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & + & {
      margin-top: 21px;
    }
    &-group {
      display: flex;
      align-items: center;
    }
    &-text {
      margin-right: 12px;
    }
  }

  &__input {
    .field {
      width: 100%;
    }
  }
  &__button {
    margin-top: 12px;
  }
}
</style>
