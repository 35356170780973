<template>
  <router-link
    :to="{ name: link }"
    class="list-link"
    active-class="active"
    v-if="link"
    >{{ title }}</router-link
  >
  <li class="list-link" v-else>{{ title }}</li>
</template>

<script>
export default {
  name: "ListLink",
  props: {
    link: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-link {
  height: 64px;
  display: flex;
  align-items: flex-end;
  color: $PureBlack;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color ease 0.2s;
  // transition: background-color 0.5s, color 0.5s;
  &.active,
  &:hover {
    color: $PureWhite;
    background: $SoothingBlue;
    // font-style: italic;
  }
  &.nested {
    color: $PureBlack;
    background: $ToxicGreenSoft;
    font-style: italic;
  }
}
</style>
