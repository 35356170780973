<template>
  <div class="toast">
    <span class="content">{{ content }}</span>
    <div class="img-wrapper">
      <img src="@/assets/icons/Checkmark_48px_2x.png" alt="checkmark" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionNotification",
  props: {
    content: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  font-style: italic;
  font-size: 16px;
  line-height: 21px;
  color: $PureWhite;
  font-family: "RobotoMono";
	display: flex;
	align-items: center;
	justify-content: space-between;
	.img-wrapper{
		width: 42px;
		height: 42px;
	}
}
</style>
