import http from "@/api/api";
import { auth } from "@/api/api";
import router from "@/router";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    items: [],
    currentItem: {
      id: null,
      name: null,
      content: null,
      min_trust_level: 50,
      abId: null,
      groupId: null
    },
    totalRecords: null,
  },
  getters: {
    items(state) {
      return state.items;
    },
    currentItem(state) {
      return state.currentItem;
    },
    totalRecords: (state) => Number(state.totalRecords),
  },
  mutations: {
    clearcurrentItem(state) {
      state.currentItem.id = null;
      state.currentItem.name = null;
      state.currentItem.content = null;
      state.currentItem.min_trust_level = 50;
    },
    getItems(state, payload) {
      state.items = payload;
    },
    getCurrentItem(state, payload) {
      state.currentItem = payload;
    },
    updateCurrentItem(state, { field, value }) {
      state.currentItem[field.toLowerCase()] = value;
    },
    getTotalRecords(state, payload) {
      state.totalRecords = payload;
    },
  },
  actions: {
    getItems(store, { appId, abId, sort, limit, offset, query }) {
      return new Promise((resolve) => {
        http
          .get(`/api/v1/dialogue/${appId}/dialogs`, {
            headers: { ...auth() },
            params: {
              limit,
              offset,
              ab_id: abId,
              sort:
                sort && sort?.sortField
                  ? `${sort.sortField}_${
                      sort?.sortOrder === 1 ? "asc" : "desc"
                    }`
                  : null,
              name: query,
            },
          })
          .then((response) => {
            const payload = response.data;
            const total = response.headers["x-total-count"];
            store.commit("getItems", payload);
            store.commit("getTotalRecords", total);
            resolve(payload);
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
    },
    async getCurrentItem(store, { appId, itemId, abId }) {
      const response = await http.get(
        `/api/v1/dialogue/${appId}/dialogs/${itemId}${abId ? `/${abId}` : ""}`,
        {
          headers: { ...auth() },
        }
      );
      const payload = response.data;
      store.commit("getCurrentItem", payload);
      return payload;
    },
    createItem(store, { appId, itemId, abId, name, content, trustLevel, groupId}) {
      http
        .post(
          `/api/v1/dialogue/${appId}/dialogs` +
            `${itemId ? "/" + itemId + "/ab" : ""}`,
          {
            name: name,
            content: content,
            min_trust_level: trustLevel,
            ab_id: abId,
            group_id: groupId? groupId: null
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "dialogs" });
          Notification(descriptions.dialogCreated, types.success);
        })
        .catch((error) => {
          Notification(descriptions.danger, types.danger);
        });
    },
    editItem(store, { appId, itemId, name, content, trustLevel, abId }) {
      http
        .patch(
          `/api/v1/dialogue/${appId}/dialogs/${itemId}` +
            `${abId ? `/${abId}` : ""}`,
          {
            name: name,
            content: content,
            min_trust_level: trustLevel,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "dialogs" });
          Notification(descriptions.dialogUpdated, types.success);
        })
        .catch((error) => {
          Notification(descriptions.danger, types.danger);
        });
    },
    async deleteItem(store, { appId, itemId, abId }) {
      try {
        const response = await http.delete(
          `/api/v1/dialogue/${appId}/dialogs/${itemId}` +
            `${abId ? `/${abId}` : ""}`,
          {
            headers: { ...auth() },
          }
        );
        Notification(descriptions.dialogDeleted, types.success);
        return;
      } catch (e) {
        console.log("err", e);
        Notification(descriptions.danger, types.danger);
      }
    },
    updateCurrentItem(store, payload) {
      store.commit("updateCurrentItem", payload);
    },
    resetCurrentItem(store) {
      store.commit("clearcurrentItem");
    },
  },
};
