<template>
  <button :type="type" @click="$emit('click')">{{ content }}</button>
</template>

<script>
export default {
  emits: {
    click: null,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "button",
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
button {
  min-width: 364px;
  height: 42px;
  outline: none;
  border: none;
  font-family: "RobotoMono";
  padding: 9px 0;
  color: $PureBlack;
  background: $PureWhite;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.4s, background-color 0.4s;
  &:active,
  &:hover,
  &.active {
    color: $SoothingBlue;
  }
  &:disabled {
    background: $InactiveGrey;
    font-style: italic;
    font-weight: 400;
    color: $PureWhite;
    pointer-events: none;
  }
}
</style>
