<template>
  <div class="container">
    <div class="form-wrapper">
      <div class="logo-wrapper">
        <img src="@/assets/logo/logo_color.png" alt="logo" />
      </div>
      <form class="signIn-form" @submit.prevent="login({ email, password })">
        <div class="form-group">
          <label>Email:</label>
          <input type="email" v-model="email" placeholder="Email" />
        </div>
        <div class="form-group">
          <label>Password:</label>
          <input :type="inputType" v-model="password" placeholder="Password" />
          <img
            class="visibility"
            src="@/assets/icons/Visibility_Off.png"
            alt="Visibility_Off"
            @click="togglePasswordVisibility(1)"
            v-if="inputType === 'password'"
          />
          <img
            class="visibility"
            src="@/assets/icons/Visibility_On.png"
            alt="Visibility_On"
            @click="togglePasswordVisibility(0)"
            v-else
          />
        </div>
        <Button
          type="submit"
          content="Sign In"
          class="btn-sign"
          :class="{ disabled: !formReady }"
          :disabled="!formReady"
        />
      </form>
      <div class="info-wrapper">
        <div class="info">
          <span class="bold">contact@</span>temporal.games<br />Ⓒ temporal games
          inc.
        </div>
        <div class="logo-wrapper">
          <img src="@/assets/logo/TemporalLogo.png" alt="logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/constructor/graphical/Button";
import { mapActions } from "vuex";

export default {
  components: {
    Button,
  },
  data() {
    return {
      email: null,
      password: null,
      inputType: "password",
    };
  },
  computed: {
    formReady() {
      return this.email && this.password;
    },
  },
  methods: {
    ...mapActions("authorization", ["login"]),
    togglePasswordVisibility(type) {
      type === 0 ? (this.inputType = "password") : (this.inputType = "text");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: unset;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background: #3f4bf2;

  .form-wrapper {
    max-width: 380px;
    margin: auto;
    .logo-wrapper {
      max-width: 402px;
      max-height: 491px;
    }
    .signIn-form {
      margin-top: 40px;
      .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        label {
          position: absolute;
          top: 50%;
          left: -90px;
          transform: translateY(-50%);
          color: #fff;
          text-align: right;
          min-width: 85px;
          font-style: italic;
        }
        input {
          padding: 10px 12px;
          background: transparent;
          border: 5px solid #fff;
          font-size: 18px;
          font-style: italic;
          line-height: 24px;
          color: #fff;
          outline: none;
          width: 100%;
        }
        .visibility {
          max-width: 42px;
          max-height: 42px;
          position: absolute;
          right: -50px;
          cursor: pointer;
        }
      }
      .btn-sign {
        outline: none;
        border: none;
        color: #3f4bf2;
        cursor: pointer;
        padding: 10px;
        text-transform: uppercase;
        font-size: 18px;
        font-style: italic;
        line-height: 24px;
        background: #fff;
        border-radius: 8px;
        width: 100%;
        transition: background-color 0.5s, color 0.5s;
        &:hover {
          background: #e0e1f5;
        }
        &.disabled {
          background: #aaaaaa;
          color: #fff;
          &:hover {
            background: #8a8989;
          }
        }
      }
    }
    .info-wrapper {
      margin-top: 60px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .info {
        font-size: 18px;
        line-height: 21px;
        color: #fff;
        text-transform: uppercase;
        .bold {
          font-weight: 700;
        }
      }
      .logo-wrapper {
        max-width: 78px;
        max-height: 78px;
      }
    }
  }
}
</style>
