<template>
  <PageDescriptorPrimary title="ACCESS-TOKEN API" content="Access-Token API." />
  <PageContent class="page-content"> </PageContent>
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
export default {
  components: {
    PageDescriptorPrimary,
    PageContent,
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  &__field-summary {
    margin-top: 12px;
  }

  &__field-input {
    :deep().field {
      width: 100%;
    }
  }
  &__switcher-sw {
    margin-top: 21px;
  }
  &__callbtn {
    margin: 21px 0;
  }
  &__btn {
    margin-top: 12px;
  }
}
</style>