<template>
  <div class="summary-commentary">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SummaryCommentary",
};
</script>

<style lang="scss">
.summary-commentary {
  display: flex;
  align-items: center;
  color: $PureBlack;
  font-size: 16px;
  line-height: 21px;
  font-style: italic;
  padding: 11px 0 10px 0;
}

.accent {
  font-weight: 700;
}

.highlight {
  color: $SoothingBlue;
}

.dark-theme {
  color: $PureWhite;
}
</style>
