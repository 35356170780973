<template>
  <div class="page-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageContent",
};
</script>

<style lang="scss" scoped>
.page-content {
  min-height: 60px;
	line-height: 42px;
}
</style>
