<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
//null
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  line-height: 1;
  font-family: "RobotoMono";
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: "RobotoMono";
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}
p {
  margin: 0;
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 700;
  font-style: normal;
  src: url(assets/fonts/RobotoMono-Bold.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 700;
  font-style: italic;
  src: url(assets/fonts/RobotoMono-BoldItalic.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 500;
  font-style: normal;
  src: url(assets/fonts/RobotoMono-Medium.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 500;
  font-style: italic;
  src: url(assets/fonts/RobotoMono-MediumItalic.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 400;
  font-style: normal;
  src: url(assets/fonts/RobotoMono-Regular.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 400;
  font-style: italic;
  src: url(assets/fonts/RobotoMono-Italic.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 300;
  font-style: normal;
  src: url(assets/fonts/RobotoMono-Light.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 300;
  font-style: italic;
  src: url(assets/fonts/RobotoMono-LightItalic.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 100;
  font-style: normal;
  src: url(assets/fonts/RobotoMono-Thin.ttf);
}

@font-face {
  font-family: "RobotoMono";
  font-weight: 100;
  font-style: italic;
  src: url(assets/fonts/RobotoMono-ThinItalic.ttf);
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.filtered {
  font-weight: 600;
}

.validationError {
  box-shadow: 0 0 10px crimson;
}

.mosha__toast {
  margin: 0 !important;
  width: 437px !important;
  height: 42px !important;
  padding: 11px 21px !important;
  .mosha__toast__content-wrapper {
    display: none;
  }
  .mosha__toast__close-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::before {
      content: unset;
    }
  }
}
</style>
