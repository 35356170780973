<template>
  <section class="export">
    <div class="loader" v-show="loading">
      <span class="loading" v-if="loading">Exporting...</span>
    </div>

    <PageDescriptorPrimary
      title="Export"
      content="Download dump of user dialogs from a given time stamp."
    />
    <PageContent>
      <div class="period">
        <Title3 content="Period" />
        <div class="range-wrapper">
          <div class="range">
            <SummaryCommentary class="info">
              <span>From:</span>
            </SummaryCommentary>
            <DateSelector
              class="date-selector"
              :date="fromDate"
              day
              @month-change="monthChangeFrom($event, 'fromDate')"
              @date-change="handleDateChange($event, 'fromDate')"
              @day-change="handleDayChange($event, 'fromDate')"
              @one-day-change="handleOneDayChange($event, 'fromDate')"
            />
          </div>
          <div class="range">
            <SummaryCommentary class="info">
              <span>To:</span>
            </SummaryCommentary>
            <DateSelector
              class="date-selector"
              :date="toDate"
              day
              @month-change="monthChangeFrom($event, 'toDate')"
              @date-change="handleDateChange($event, 'toDate')"
              @day-change="handleDayChange($event, 'toDate')"
              @one-day-change="handleOneDayChange($event, 'toDate')"
            />
          </div>
        </div>
      </div>
      <div class="filters">
        <Title3 content="Filters" />
        <div class="filters-item">
          <SummaryCommentary class="info">
            <span>Minimal phrase count:</span>
          </SummaryCommentary>
          <ValueField
            fieldType="input"
            class="input"
            name="count"
            :value="minimalPhrase"
            @input="handleInput"
          />
        </div>
      </div>
      <Button class="btn" content="Export archive" @click="exportData"></Button>
    </PageContent>
  </section>
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import Title3 from "@/components/constructor/textual/Title3";
import DateSelector from "@/components/constructor/graphical/DateSelector";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import ValueField from "@/components/constructor/graphical/ValueField";
import Button from "@/components/constructor/graphical/Button";
import moment from "moment";
import axios from "@/api/api";
import { auth } from "@/api/api";
import Notification from "@/helpers/Notifications";
import { types, descriptions } from "@/helpers/Notifications";
import JSZip from "jszip";

export default {
  name: "Export",
  components: {
    PageDescriptorPrimary,
    PageContent,
    Title3,
    DateSelector,
    SummaryCommentary,
    ValueField,
    Button,
  },
  data: () => ({
    fromDate: new Date(),
    toDate: new Date(),
    loading: false,
    minimalPhrase: 40,
  }),
  methods: {
    monthChangeFrom(direction, date) {
      if (direction === 1) {
        this[date] = moment(this[date]).add(1, "month").toDate();
      } else {
        this[date] = moment(this[date]).add(-1, "month").toDate();
      }
    },
    handleOneDayChange(direction, date) {
      if (direction === 1) {
        this[date] = moment(this[date]).add(1, "day").toDate();
      } else {
        this[date] = moment(this[date]).add(-1, "day").toDate();
      }
    },
    handleDateChange(date, variable) {
      this[variable] = date;
    },
    handleDayChange(date, variable) {
      this[variable] = date;
    },
    async exportData() {
      try {
        Notification(descriptions.exportStarted, types.success);
        this.loading = true;
        const response = await axios.get(
          `api/v1/dialogue/${localStorage.getItem("appId")}/memory-export`,
          {
            headers: { ...auth() },
            params: {
              timestamp_start: moment(this.fromDate).format("X"),
              timestamp_end: moment(this.toDate).format("X"),
              min_phrase_cnt: this.minimalPhrase,
            },
          }
        );

        if (response?.data.length > 0) {
          let filesToArchive = [];

          response?.data.forEach((item) => {
            filesToArchive.push(this.downloadJsonFile(item));
          });

          let zip = new JSZip();

          filesToArchive.forEach((file, i) => {
            zip.file("file" + i + ".json", file);
          });

          zip
            .generateAsync({
              type: "base64",
            })
            .then((content) => {
              window.location.href = "data:application/zip;base64," + content;
            });
          Notification(descriptions.exportComplete, types.success);
        } else {
          Notification(descriptions.exporEmpty, types.success);
        }
      } catch (e) {
        Notification(e.response?.data?.detail, types.danger);
      } finally {
        this.loading = false;
      }
    },
    downloadJsonFile(data) {
      const blob = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });

      return blob;
    },
    handleInput(val) {
      this.minimalPhrase = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.export {
  position: relative;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(27, 30, 36, 0.7);
    width: 100%;
    height: 100%;
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 32px;
    }
  }
  .period {
    .range-wrapper {
      margin-top: 12px;
      .range {
        display: flex;
        align-items: center;
        gap: 13px;
        .info {
          width: 47px;
        }
      }
    }
  }
  .filters {
    .filters-item {
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      .info {
        text-transform: capitalize;
      }
      .input {
        max-width: 94px;
        min-width: unset;
      }
    }
  }
  .btn {
    margin-top: 60px;
  }
}
</style>
