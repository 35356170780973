import axios from "axios";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_FLUXCORTEX_API_URL,
});

const auth = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      router.push({ name: "signIn" });
      localStorage.clear();
    }

    if (error?.response?.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error?.config?.url == "/api/v1/auth/refresh") {
      router.push({ name: "signIn" });
      localStorage.clear();

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    const refresh = localStorage.getItem("refresh");

    return http
      .post(
        "/api/v1/auth/refresh",
        {},
        {
          headers: { Authorization: `Bearer ${refresh}` },
        }
      )
      .then((response) => {
        const config = error?.config;
        config.headers[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`;
        localStorage.setItem("access_token", response.data.access_token);

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
);

export { auth };

export default http;
