<template>
  <PageDescriptorSub
    :title="headerInfo.title"
    :content="headerInfo.content"
    @close="goBack"
  />
  <div class="content">
    <Title3 :content="createdAt" v-if="id" />
    <div class="content__item" v-if="id">
      <Title3 content="Id" class="content__item-title"></Title3>
      <SummaryCommentary
        >Identifier for this character. Used in API requests.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="id"
        :value="currentItem?.id"
        disabled
      />
    </div>
    <div class="content__item">
      <Title3 content="Name" class="content__item-title"></Title3>
      <SummaryCommentary
        >How the character is called and names itself.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="name"
        :value="currentItem.name"
        @input="handleUpdate($event, 'name')"
        placeholder="Write Character`s Name"
      />
    </div>
    <div class="content__item">
      <Title3 content="Persona" class="content__item-title"></Title3>
      <SummaryCommentary
        >Persona description used by the character itself.</SummaryCommentary
      >
      <ValueField
        class="content__item-form content__item-form--textarea"
        name="persona"
        :value="currentItem?.persona"
        @input="handleUpdate($event, 'persona')"
        placeholder="Write Character's Description"
        fieldType="textarea"
        maxLenght="2000"
      />
    </div>
    <div class="content__item voice-block">
      <div class="loader" v-show="loading">
        <span class="loading">Loading...</span>
      </div>
      <Title3 content="VOICE" class="content__item-title"></Title3>
      <SummaryCommentary>TTS voice type for this character.</SummaryCommentary>
      <div class="content__item-group">
        <div class="voice-img" @click="handleAudioPlay">
          <img src="@/assets/icons/Audio_42px_2x.png" alt="img" />
        </div>
        <OptionsDropdown
          :content="voiceSelectActive?.label"
          placeholder="Pick a Character's Voice"
        >
          <ul class="dropdown-list">
            <ListLink
              class="dropdown-list__item"
              v-for="item in voices"
              :title="item.label"
              :key="item.label"
              @click="setVoiceActive(item)"
            />
          </ul>
        </OptionsDropdown>
      </div>
      <div class="content__item-group">
        <div class="checkbox">
          <input id="checkbox" type="checkbox" v-model="customPhrase" />
          <label for="checkbox"></label>
        </div>
        <SummaryCommentary class="content__item--textmr"
          >Use custom test line:</SummaryCommentary
        >
        <ValueField
          class="content__item-form"
          name="Test line"
          fieldType="input"
          :value="phrase"
          @input="handleCustomPhraseInput"
          :disabled="!customPhrase"
        />
      </div>
    </div>
    <div class="content__item">
      <Title3
        content="DATING: PREFERRED PARTNER APPEARANCE"
        class="content__item-title"
      ></Title3>
      <SummaryCommentary
        >Description for the preferred appearance of a dating
        partner.</SummaryCommentary
      >
      <ValueField
        class="content__item-form content__item-form--textarea"
        name="dating"
        :value="currentItem?.dating"
        placeholder="Write Ideal Partner Description"
        fieldType="textarea"
        @input="handleUpdate($event, 'dating')"
      />
    </div>
    <div class="content__item">
      <Title3 content="ROMANTIC BEHAVIOR" class="content__item-title"></Title3>
      <SummaryCommentary
        >Description for the preferred appearance of a dating
        partner.</SummaryCommentary
      >
      <OptionsSwitcher
        :options="options"
        :activeOption="activeOption"
        @onChange="handleChange"
        name="is_romantic_enable"
      />
    </div>
    <div class="content__item">
      <Title3
        content="ATTACHED DIALOGUE SEQUENCES"
        class="content__item-title"
      ></Title3>
      <SummaryCommentary
        >Applies selected scripted Dialogue Sequences to this character.
        Multiple Sequences can be defined.</SummaryCommentary
      >
      <OptionsMultiDropdown
        placeholder="Pick Dialogue Sequences"
        :tags="dialogues"
        :tagsActive="dialogsTagsActive"
        @pushTag="pushActiveTag"
        @removeTag="removeActiveTag"
      ></OptionsMultiDropdown>
    </div>
    <div class="content__btns">
      <div class="content__btns-item">
        <Button
          v-if="id"
          @click="
            abId && hasAbId
              ? handleEdit(currentItem, abId)
              : abId && !hasAbId
              ? handleCreate(currentItem, abId)
              : handleEdit(currentItem)
          "
          content="Save"
          :disabled="!wasChanged"
        />
        <Button
          @click="handleCreate(currentItem)"
          content="Create"
          :disabled="!formReady"
          v-else
        />
      </div>
      <div class="content__btns-item">
        <Button @click="goBack" :content="discardChangesBtnText" />
      </div>
    </div>
  </div>
  <Modal
    :display="displayModal"
    title="Discard changes"
    content="You have unsaved changes on this page. Are you sure that you want to discard them?"
    btn="Yes, Discard"
    @onClose="discardChanges"
    @onConfirm="confirmChanges"
  />
</template>

<script>
import Button from "@/components/constructor/graphical/Button";
import Title3 from "@/components/constructor/textual/Title3";
import OptionsDropdown from "@/components/constructor/textual/OptionsDropdown";
import OptionsMultiDropdown from "@/components/constructor/textual/OptionsMultiDropdown";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import ListLink from "@/components/constructor/textual/ListLink";
import ValueField from "@/components/constructor/graphical/ValueField";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import PageDescriptorSub from "@/components/frame/PageDescriptorSubPage";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import moment from "moment";
import Modal from "@/components/frame/Popup";
import _ from "lodash";
import http from "@/api/api";
import { auth } from "@/api/api";
import Notification from "@/helpers/Notifications";
import { types } from "@/helpers/Notifications";

export default {
  name: "Characters",
  beforeRouteLeave(from, to, next) {
    if (this.wasChanged && !this.saveData) {
      this.confirm().then((result) => {
        if (result) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  components: {
    Button,
    Title3,
    ListLink,
    OptionsDropdown,
    OptionsMultiDropdown,
    OptionsSwitcher,
    ValueField,
    SummaryCommentary,
    PageDescriptorSub,
    Modal,
  },
  created() {
    this.getVoices().then(() => {
      this.getDialogs(this.appId)
        .then(() => {
          if (this.id) {
            this.getCurrentItem({
              appId: this.appId,
              itemId: this.id,
              abId: this.abId && this.hasAbId ? this.abId : null,
            }).then(() => {
              const voice = this.voices.find(
                (obj) => obj.value === this.currentItem.voiceId
              );
              this.voiceSelectActive = { ...voice };

              const dialogs = this.dialogues.filter((el) => {
                return this.currentItem.dialogs.find((obj) => obj === el.value);
              });
              this.dialogsTagsActive = [...dialogs];

              this.activeOption = this.currentItem.is_romantic_enable ? 0 : 1;
              this.currentItemDefault = _.clone(this.currentItem);

              this.defaultPhrase = `Hi, my name is ${this.currentItem.name}`;
              this.phrase = `Hi, my name is ${this.currentItem.name}`;
            });
          } else {
            this.resetCurrentItem();
            this.defaultPhrase = `Hi, my name is new character`;
          }
        })
        .finally(() => {
          this.currentItemDefault = _.clone(this.currentItem);
        });
    });
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      id: this.$route.params.id ?? null,
      abId:
        this.$route.params.abId &&
        this.$route.params.abId !== "undefined" &&
        this.$route.params.abId !== "null"
          ? this.$route.params.abId
          : null,
      hasAbId: this.$route.params.hasAbId
        ? JSON.parse(this.$route.params.hasAbId)
        : null,
      voiceSelectActive: null,
      options: [
        {
          id: 0,
          value: "Enabled",
        },
        {
          id: 1,
          value: "Disabled",
        },
      ],
      dialogsTagsActive: [],
      activeOption: 1,
      displayModal: false,
      resolvePromise: null,
      currentItemDefault: null, //исходное значение
      audio: null,
      defaultPhrase: null,
      phrase: "Hi, my name is new character",
      customPhrase: false,
      loading: false,
      saveData: false,
    };
  },
  methods: {
    ...mapActions("characters", [
      "getCurrentItem",
      "updateCurrentItem",
      "resetCurrentItem",
      "createItem",
      "editItem",
      "getVoices",
      "getDialogs",
    ]),
    setVoiceActive(itemName) {
      this.voiceSelectActive = itemName;
      this.updateCurrentItem({
        field: "voiceId",
        value: this.voiceSelectActive.value,
      });
    },
    confirm() {
      this.displayModal = true;
      return new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
    },
    goBack() {
      this.$router.push({ name: "characters" });
    },
    discardChanges() {
      this.displayModal = false;
      this.resolvePromise(false);
    },
    confirmChanges() {
      this.resolvePromise(true);
    },
    handleCreate(currentItem, abId) {
      const {
        id,
        name,
        persona,
        dating,
        voiceId,
        is_romantic_enable,
        dialogs,
      } = currentItem;
      this.saveData = true;
      this.createItem({
        appId: this.appId,
        name: name,
        persona: persona,
        dating: dating,
        voiceId: voiceId,
        isRomanticEnable: is_romantic_enable,
        dialogs: dialogs,
        itemId: id,
        abId: abId,
      });
    },
    handleEdit({
      id,
      name,
      persona,
      dating,
      voiceId,
      is_romantic_enable,
      dialogs,
    }) {
      this.saveData = true;
      this.editItem({
        appId: this.appId,
        itemId: id,
        name: name,
        persona: persona,
        dating: dating,
        voiceId: voiceId,
        isRomanticEnable: is_romantic_enable,
        dialogs: dialogs,
        abId: this.abId,
      });
    },
    handleUpdate(val, field) {
      this.updateCurrentItem({
        field: field,
        value: val,
      });
    },
    handleSelect() {
      this.updateCurrentItem({
        field: "dialogs",
        value: this.dialogsTagsActive.map((item) => item.value),
      });
    },
    handleChange(data) {
      this.activeOption = data.id;
      this.updateCurrentItem({
        field: "is_romantic_enable",
        value: data.value === "Enabled",
      });
    },
    pushActiveTag(obj) {
      this.dialogsTagsActive.push(obj);
      this.handleSelect();
    },
    removeActiveTag(id) {
      this.dialogsTagsActive.splice(
        this.dialogsTagsActive.findIndex((obj) => obj.value === id),
        1
      );
      this.handleSelect();
    },
    async handleAudioPlay() {
      const response = await http.post(
        `/api/v1/dialogue/${this.appId}/pronounce`,
        {
          voice_id: this.currentItem?.voiceId,
          text: this.customPhrase ? this.phrase : this.defaultPhrase,
          tts_processing_mode: "Single",
          tts_result_format: "Raw",
          tts_result_extension: "wav",
        },
        {
          headers: { ...auth() },
        }
      );

      if (response?.data) {
        this.loading = true;

        const timerId = setInterval(async () => {
          try {
            const res = await http.post(
              `/api/v1/dialogue/pronounce/result`,
              {
                pronounce_id: response?.data?.pronounce_id,
              },
              {
                headers: { ...auth() },
              }
            );

            if (res?.status === 200) {
              this.loading = false;
              this.audio = res?.data?.voice;
              let snd = new Audio("data:audio/wav;base64," + this.audio);
              snd.play();
              clearInterval(timerId);
              return;
            }
          } catch (e) {
            Notification(e.response?.data?.detail, types.danger);
            this.loading = false;
            clearInterval(timerId);
            return;
          }
        }, 3000);
      }
    },
    handleCustomPhraseInput(val) {
      this.phrase = val;
    },
  },
  computed: {
    ...mapGetters("characters", [
      "currentItem",
      "voices",
      "dialogues",
      "attachedDialogues",
      "radio",
    ]),
    headerInfo() {
      return this.id
        ? {
            title: "Characters / Editor",
            content:
              "Edit an AI-character profile, configure its voice, persona, and advanced settings.",
          }
        : {
            title: "Characters / Creator",
            content:
              "Create an AI-character profile, configure its voice, persona, and advanced settings.",
          };
    },
    createdAt() {
      return `created on ${moment(this.currentItem?.created_at).format(
        "yyyy MMM DD hh:mm:ss"
      )}`;
    },
    formReady() {
      return this.currentItem.name && this.currentItem.name.length > 0;
    },
    wasChanged() {
      return !_.isEqual(this.currentItem, this.currentItemDefault);
    },
    discardChangesBtnText() {
      return this.wasChanged ? "Discard" : "Cancel";
    },
  },
  watch: {
    "currentItem.name"() {
      this.defaultPhrase = `Hi, my name is ${this.currentItem.name}`;
      this.phrase = `Hi, my name is ${this.currentItem.name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.voice-block {
  position: relative;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(27, 30, 36, 0.7);
    width: 100%;
    height: 100%;
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 32px;
    }
  }
}
.subtitle {
  margin-top: 20px;
}

.dropdown-list {
  height: 200px;
  padding: 12px 0;
  overflow: auto;
  &__item {
    height: 46px;
    padding: 0 21px;
    transition: background-color 0.5s, color 0.5s;
    align-items: center;
    & + & {
      margin-top: 10px;
    }
    &:hover {
      background: #3f4bf2;
      color: #fff;
    }
  }
}
.voice-img {
  width: 42px;
  height: 42px;
  cursor: pointer;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.checkbox {
  margin-right: 12px;
  > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    & + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
    }
    & + label::before {
      content: "";
      display: inline-block;
      width: 42px;
      height: 42px;
      background: url(../../../assets/icons/Checkmark_Off_42px_2x.png)
        center/contain no-repeat;
    }

    &:checked + label::before {
      background-image: url(../../../assets/icons/Checkmark_On_42px_2x.png);
    }
  }
}
.content {
  &__item {
    & + .content__btns {
      margin-top: 60px;
    }
    &-title {
      margin-bottom: 12px;
    }
    &-form {
      width: 100%;
      &.content__item-form--textarea {
        :deep().field {
          height: 90px;
        }
      }
      :deep().field {
        width: 100%;
      }
    }
    &-limit {
      justify-content: right;
    }
    &-group {
      display: flex;
      align-items: center;
      .content__item-form {
        flex: 1 1 auto;
      }
      & + & {
        margin-top: 11px;
      }
      .voice-img {
        margin-right: 12px;
      }
    }
    .content__item--textmr {
      margin-right: 12px;
      min-width: 200px;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    &-item {
      & + & {
        margin-left: 12px;
      }
    }
  }
}

.customInput,
.select {
  margin-top: 30px;
}

.success {
  margin-top: 30px;
  margin-bottom: 50px;
}

.select {
  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .description {
    margin-top: 5px;
    font-size: 16px;
    color: #585454;
  }
  .multiselect {
    margin-top: 5px;
    :deep() .multiselect-input {
      max-width: 600px;
      margin-top: 5px;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
      padding: 6px 8px;
      font-size: 20px;
      color: #585858;
      font-weight: 500;
    }
  }

  .is-tags {
    :deep() .multiselect-input {
      width: 100%;
      .multiselect-tags {
        margin-top: 0;
        .multiselect-tag {
          background: #eff6ff;
          color: #585858;
          border: 1px solid #98b5df;
          padding-top: 5px;
          padding-bottom: 5px;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
