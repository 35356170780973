<template>
  <div class="pagination">
    <div class="pagination__nav">
      <div class="pagination__nav-arrow">
        <button
          class="pagination__nav-button"
          :class="{ disabled: prevPageNavDisabled }"
          @click="startPage"
          :disabled="prevPageNavDisabled"
        >
          <img
            src="@/assets/icons/Arrow_Square_LeftDouble_Small_2x.png"
            alt="arrow"
          />
        </button>
      </div>
      <div class="pagination__nav-arrow">
        <button
          class="pagination__nav-button"
          :class="{ disabled: prevPageNavDisabled }"
          @click="prevPage"
          :disabled="prevPageNavDisabled"
        >
          <img
            src="@/assets/icons/Arrow_Square_Left_Small_2x.png"
            alt="arrow"
          />
        </button>
      </div>
      <div class="pagination__nav-box">
        <ul class="pagination-list">
          <div
            class="pagination-list__item"
            v-for="(item, i) in paginationSize"
            :key="i"
          >
            <button
              class="pagination-list__item-button"
              :class="{ 'is-active': item - 1 === activePage }"
              @click="handleChange(item - 1)"
            >
              {{ item }}
            </button>
          </div>
        </ul>
      </div>
      <div class="pagination__nav-arrow">
        <button
          class="pagination__nav-button"
          :class="{ disabled: nextPageNavDisabled }"
          @click="nextPage"
          :disabled="nextPageNavDisabled"
        >
          <img
            src="@/assets/icons/Arrow_Square_Right_Small_2x.png"
            alt="arrow"
          />
        </button>
      </div>
      <div class="pagination__nav-arrow">
        <button
          class="pagination__nav-button"
          :class="{ disabled: nextPageNavDisabled }"
          @click="lastPage"
          :disabled="nextPageNavDisabled"
        >
          <img
            src="@/assets/icons/Arrow_Square_RightDouble_Small_2x.png"
            alt="arrow"
          />
        </button>
      </div>
    </div>
    <div class="pagination__info">
      <div class="pagination__info-input">
        <ValueField
          class="input-field"
          name="paginationPage"
          fieldType="input"
          :value="activePage + 1"
          @change="
            handleChange(Number($event.target.value.replace(/[^\d]/g, '')) - 1)
          "
          @input="inputResize"
          ref="input"
        />
      </div>
      <div class="pagination__info-text">
        <SummaryCommentary>of {{ pagesTotalSize }} pages.</SummaryCommentary>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import ValueField from "@/components/constructor/graphical/ValueField";

export default {
  name: "Pagination",
  components: {
    SummaryCommentary,
    ValueField,
  },
  props: {
    colRows: {
      type: Number,
      required: true,
    },
    totalSize: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.inputResize();
  },
  watch: {
    page() {
      this.activePage = this.page;
    },
  },
  data() {
    return {
      activePage: 0,
    };
  },
  methods: {
    handleChange(page) {
      if (page >= this.pagesTotalSize) {
        this.activePage = this.pagesTotalSize - 1;
      } else if (page <= 0) {
        this.activePage = 0;
      } else {
        this.activePage = page;
      }
      this.$emit("page-change", this.activePage);
    },
    nextPage() {
      if (this.activePage < this.pagesTotalSize - 1) {
        this.handleChange(this.activePage + 1);
      }
    },
    prevPage() {
      if (this.activePage > 0) {
        this.handleChange(this.activePage - 1);
      }
    },
    startPage() {
      this.handleChange(0);
    },
    lastPage() {
      this.handleChange(this.pagesTotalSize - 1);
    },
    inputResize() {
      const int = 17;
      this.$refs.input.$el.style.width =
        (this.$refs.input.$el.value.length + 1) * int + "px";
    },
  },
  computed: {
    pagesTotalSize() {
      const size = Math.ceil(this.totalSize / this.colRows);
      return size > 0 ? size : 1;
    },
    paginationSize() {
      const numberOfPages = this.pagesTotalSize;
      const visiblePages = Math.min(5, numberOfPages);

      //calculate range, keep current in middle if necessary
      let start = Math.max(0, Math.ceil(this.activePage - visiblePages / 2));
      let end = Math.min(numberOfPages - 1, start + visiblePages - 1);

      //check when approaching to last page
      const delta = 5 - (end - start + 1);
      start = Math.max(0, start - delta);

      let pageLinks = [];

      for (let i = start; i <= end; i++) {
        pageLinks.push(i + 1);
      }

      return pageLinks;
    },
    prevPageNavDisabled() {
      return this.activePage === 0;
    },
    nextPageNavDisabled() {
      return this.activePage === this.paginationSize.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.input-field {
  text-align: center;
  max-width: 99.9% !important;
  transition: width 0.25s;
}
.pagination {
  display: flex;
  align-items: center;
  &__nav {
    display: flex;
    align-items: center;
  }
  &__info {
    display: flex;
    align-items: center;
    &-input {
      .field {
        min-width: 35px;
        max-width: 50px;
        padding: 4px 8px;
      }
    }
    &-text {
      margin-left: 12px;
    }
  }
  &__nav-arrow {
    display: flex;
    width: 42px;
    height: 42px;
  }

  &__nav-button {
    width: 100%;
    height: 100%;
    background: transparent;
    > img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }

  &__nav-box {
    margin: 0 6px;
    height: 100%;
  }
}
.pagination-list {
  display: flex;
  margin: 0 -2px;
  &__item {
    padding: 0 2px;
    width: 36px;
  }

  &__item-button {
    font-family: "RobotoMono";
    font-size: 18px;
    line-height: 24px;
    color: $PureBlack;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: all 0.5s ease;
    &.is-active {
      background-color: $SoothingBlue;
      color: $PureWhite;
      font-style: italic;
    }
  }
}
</style>
