<template>
  <PageDescriptorPrimary
    title="Application"
    content="Information on your currently selected application and its managers."
  />
  <PageContent class="page-content">
    <Title2 content="Application Information" />
    <Title3 :content="createdAt" />
    <div class="page-content__field">
      <Title3 content="ID" />
      <SummaryCommentary class="page-content__field-summary"
        >Identifier for this Application. Used in API
        requests.</SummaryCommentary
      >
      <ValueField
        class="page-content__field-input"
        disabled
        :value="appId"
        name="identifieOrganiztion"
        fieldType="input"
      />
    </div>
    <div class="page-content__field">
      <Title3 content="NAME" />
      <SummaryCommentary class="page-content__field-summary"
        >The name of the Application.</SummaryCommentary
      >
      <ValueField
        class="page-content__field-input"
        name="organization"
        placeholder="You'r Application"
        fieldType="input"
        :value="currentApp ? currentApp.name : ''"
        @input="handleEditInput"
      />
    </div>
    <div class="page-content__btn">
      <Button content="APPLY" @click="updateOrganizationInfo" />
    </div>
    <div class="page-content__switcher">
      <Title2 content="Application Managers List" />
      <OptionsSwitcher
        class="page-content__switcher-sw"
        :options="optionsRows"
        :activeOption="activeRows.id"
        @onChange="handleChangeRows"
        name="rows"
      />
    </div>
    <div class="page-content__callbtn">
      <ActionCallButton
        content="+ Add Manager by Email"
        @click="handleCreate"
      />
    </div>
    <Table
      :tableData="managersList"
      :tableColumns="columns"
      :loading="loading"
      :totalRecords="totalManagersCount"
      :activeRows="activeRows.value"
      :id="false"
      :page="currentPage"
      paginationItems="managers"
      actions="remove"
      @onEdit="handleEdit"
      @onRemove="confirmRemove"
      @onSorting="sort"
      @onPageChange="handlePageChange"
    />
  </PageContent>
  <Modal
    :display="displayModal"
    :title="modalContent?.title"
    :content="modalContent?.content"
    :btn="modalContent?.btn"
    @onClose="closeModal"
    @onConfirm="modalContent?.method()"
  >
    <ValueField
      class="manager-input"
      name="email"
      placeholder="Write Manager's Email"
      fieldType="input"
      @input="handleEmailInput"
      v-if="activeModal === 'create'"
    ></ValueField>
  </Modal>
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import Title2 from "@/components/constructor/textual/Title2";
import Title3 from "@/components/constructor/textual/Title3";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import ValueField from "@/components/constructor/graphical/ValueField";
import Button from "@/components/constructor/graphical/Button";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import ActionCallButton from "@/components/constructor/graphical/ActionCallButton";
import Table from "@/components/constructor/graphical/Table";
import Modal from "@/components/frame/Popup";
import http from "@/api/api";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";
import { auth } from "@/api/api";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Organization",
  components: {
    PageDescriptorPrimary,
    PageContent,
    Title2,
    Title3,
    SummaryCommentary,
    ValueField,
    Button,
    OptionsSwitcher,
    ActionCallButton,
    Table,
    Modal,
  },
  async created() {
    this.getManagersData();
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      columns: [{ field: "email", header: "Email", sortable: true }],
      currentRowId: null,
      loading: false,
      tests: null,
      sortObj: null,
      optionsRows: [
        {
          id: 0,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
        {
          id: 2,
          value: 30,
        },
      ],
      activeRows: {
        id: 0,
        value: 10,
      },
      organization: null,
      managersList: null,
      currentPage: 0,
      displayModal: false,
      activeModal: null, //remove, create
      email: null,
    };
  },
  methods: {
    ...mapActions("apps", ["editApp", "getApps", "setCurrentApp"]),
    handleEditInput(val) {
      this.organization = val;
    },
    handleEmailInput(val) {
      this.email = val;
    },
    updateOrganizationInfo() {
      this.editApp({ appId: this.appId, name: this.organization }).then(() => {
        this.getApps().then(() => {
          const app = this.appsList.find((obj) => obj.id === this.appId);
          this.setCurrentApp(app);
        });
      });
    },
    handleCreate() {
      this.activeModal = "create";
      this.displayModal = true;
    },
    async confirmCreate() {
      this.loading = true;
      try {
        const response = await http.post(
          `/api/v1/apps/${this.appId}/managers`,
          {
            email: this.email,
          },
          { headers: { ...auth() } }
        );
        this.getManagersData(this.sortObj);
        Notification(descriptions.managerAdded, types.success);
        this.closeModal();
      } catch (e) {
        Notification(e?.response?.data?.detail, types.danger);
      } finally {
        this.loading = false;
      }
    },
    confirmRemove(id) {
      this.activeModal = "remove";
      this.currentRowId = id;
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    async handleDelete() {
      const { email } = this.currentRowId;
      this.closeModal();
      this.loading = true;
      try {
        const response = await http.delete(
          `/api/v1/apps/${this.appId}/managers/${email}`,
          { headers: { ...auth() } }
        );
        this.getManagersData(this.sortObj);
        Notification(descriptions.managerDeleted, types.danger);
      } catch (e) {
        Notification(e?.response?.data?.detail, types.danger);
      } finally {
        this.loading = false;
      }
    },
    sort(data) {
      this.loading = true;
      this.sortObj = data;

      this.getManagersData(data).finally(() => {
        this.loading = false;
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loading = true;

      this.getManagersData(this.sortObj).finally(() => {
        this.loading = false;
      });
    },
    handleChangeRows(data) {
      const prev = this.activeRows.value;
      this.activeRows = data;

      this.currentPage =
        Math.ceil((prev * this.currentPage + 1) / this.activeRows.value) - 1;
      this.getManagersData(this.sortObj).finally(() => {
        this.loading = false;
      });
    },
    async getManagersData(sort) {
      try {
        const response = await http.get(`/api/v1/apps/${this.appId}/managers`, {
          headers: { ...auth() },
          params: {
            limit: this.activeRows.value,
            offset: this.currentPage * this.activeRows.value,
            sort:
              sort && sort?.sortField
                ? `${sort.sortField}_${sort?.sortOrder === 1 ? "asc" : "desc"}`
                : null,
          },
        });
        this.managersList = response?.data;
        this.totalManagersCount = response?.headers["x-total-count"]
      } catch (e) {
        console.log(e.data);
      }
    },
  },
  computed: {
    ...mapGetters("apps", ["currentApp", "appsList"]),
    createdAt() {
      return `created on ${moment(this.currentItem?.created_at).format(
        "yyyy MMM DD hh:mm:ss"
      )}`;
    },
    modalContent() {
      let modalInfo = null;
      switch (this.activeModal) {
        case "create":
          modalInfo = {
            title: "Add manager",
            content: "Email:",
            btn: "Add",
            method: this.confirmCreate,
          };
          break;
        case "remove":
          modalInfo = {
            title: "Remove?",
            content: "You sure that you want to remove chosen element?",
            btn: "Yes, remove",
            method: this.handleDelete,
          };
          break;
      }
      return modalInfo;
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp?.id;
      this.organization = this.currentApp?.name;
      this.getManagersData();
    },
  },
};
</script>

<style lang="scss" scoped>
.manager-input {
  width: 100%;
}
.page-content {
  &__field-summary {
    margin-top: 12px;
  }

  &__field-input {
    width: 100%;
  }
  &__switcher-sw {
    margin-top: 21px;
  }
  &__callbtn {
    margin: 21px 0;
    .actionBtn {
      text-transform: none;
    }
  }
  &__btn {
    margin-top: 12px;
  }
  :deep() .p-datatable-thead {
    th {
      max-width: 100px;
      width: 100px;
      &:first-child {
        max-width: 100px !important;
        width: 100px !important;
      }
      &:nth-child(2){
        max-width: 250px !important;
        width: 250px !important;
      }

      &:last-child {
        max-width: 243px;
        width: 243px;
      }
    }
  }
}
</style>
