<template>
  <div class="accordion">
    <div class="expand" :class="{ active: showContent }" @click="toogle">
      <span class="icon">
        <img
          src="@/assets/icons/arrow_down_icon.svg"
          alt="expand"
          class="arrow"
        />
      </span>
      <Title class="custom" :content="title" />
    </div>
    <transition name="slide-fade">
      <div class="panel" v-if="showContent">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Title from "@/components/constructor/textual/Title3";

export default {
  components: {
    Title,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showContent: true,
    };
  },
  methods: {
    toogle() {
      this.showContent = !this.showContent;
    },
  },
};
</script>

<style lang="scss" scoped>

.accordion{
  display: flex;
  flex-direction: column;
}
.expand {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  margin-left: 7px;
  &.active {
    .icon {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  span {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .icon {
    max-width: 1.5rem;
    max-height: 1.5rem;
    .arrow {
      transition: transform ease 0.1s;
    }
  }
  .custom{
    margin-left: 8px;
  }
}

.panel {
  padding: 0;
  overflow: hidden;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
