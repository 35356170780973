import { createToast, withProps } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import ActionNotification from "../../components/frame/ActionNotification.vue";

export default (description, type) => {
  return createToast(withProps(ActionNotification, { content: description }), {
    position: "bottom-right",
    type: type,
    timeout: 3000,
    transition: "zoom",
    hideProgressBar: true,
    toastBackgroundColor: "rgba(0, 0, 0, 0.75)",
  });
};

const descriptions = {
  successAdd: "Entry Created.",
  successEdit: "App Changed.",
  successDelete: "Entry removed",
  danger: "An error occurred on the server, please try again",
  warning: "Fill all the fields",
  userNotFound: "User is not found! Check your email!",
  invalidPassword: "Invalid password!",
  applicationUpdate: "New Application Name Applied",
  characterCreated: "New Character Created",
  characterUpdated: "Character Updated",
  characterDeleted: "Character Deleted",
  dialogCreated: "New Dialog Created",
  dialogUpdated: "Dialog Updated",
  dialogDeleted: "Dialog Deleted",
  testCreated: "New A/B Test Created",
  testUpdated: "A/B Test Updated",
  testDeleted: "A/B Test Deleted",
  questionCreated: "New Question Created",
  questionUpdated: "Question Updated",
  questionDeleted: "Question Deleted",
  commandCreated: "Command Created",
  commandUpdated: "Command Updated",
  commandDeleted: "Command Deleted",
  userUpdated: "User Updated",
  managerAdded: "Manager Added",
  managerDeleted: "Manager Deleted",
  tokenAdded: "Token Added",
  tokenCopied: "Token Value Copied",
  tokenDeleted: "Token Deleted",
  exportStarted: "Damp Download will Begin Shortly",
  exportComplete: "Damp Exported",
  exporEmpty: "There is no data to export"
};

const types = {
  danger: "danger",
  success: "success",
  warning: "warning",
};

export { descriptions, types };
