<template>
  <Dialog
    :visible="display"
    :style="{ width: '350px' }"
    :closable="false"
    :modal="true"
  >
    <template #header>
      <Title class="modal-title" :content="title" />
      <button class="img-wrapper" @click="onCloseModal">
        <img
          src="@/assets/icons/Cross_58px_2x.png"
          alt="Cross_58px_2x"
          v-if="closeIcon"
        />
      </button>
    </template>
    <div class="confirmation-content">
      <Summary class="content" :str1="content" />
      <slot></slot>
    </div>
    <template #footer>
      <Button class="btn-action" :content="btn" @click="onConfirm" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Title from "@/components/constructor/textual/Title1";
import Summary from "@/components/constructor/textual/Summary";
import Button from "@/components/constructor/graphical/Button";

export default {
  components: {
    Dialog,
    Title,
    Summary,
    Button,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    btn: {
      type: String,
      required: true,
    },
    closeIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("onClose");
    },
    onConfirm() {
      this.$emit("onConfirm");
    },
  },
};
</script>

<style lang="scss">
.p-dialog-mask.p-component-overlay {
  background: $AshBlack;
  .p-dialog {
    width: 636px !important;
    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
      background: #f6f6f6;
      border: none;
      padding: 0 60px;
    }
    .p-dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .modal-title {
        align-items: center;
        flex: 1 1;
      }
      .img-wrapper {
        width: 58px;
        height: 58px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
    .p-dialog-content {
      .confirmation-content {
        margin-top: 12px;
        .content {
          align-items: flex-start;
        }
      }
    }

    .p-dialog-footer {
      text-align: center;
      padding-bottom: 60px;
      .btn-action {
        margin-top: 38px;
      }
    }
  }
}
</style>
