<template>
  <div class="root">
    <Header :email="email" v-if="!pageNotFound" />
    <div class="wrapper">
      <LeftMenu v-if="!pageNotFound" />
      <div class="container">
        <div class="container__wrap">
          <router-view />
        </div>
      </div>
    </div>
    <Footer v-if="!pageNotFound" />
  </div>
</template>

<script>
import Header from "@/components/frame/Header";
import Footer from "@/components/frame/Footer";
import LeftMenu from "@/components/frame/LeftMenu";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  components: {
    Header,
    LeftMenu,
    Footer,
  },
  created() {
    this.getUser().then(() => {
      this.getApps().then(() => {
        const appId = localStorage.getItem("appId");
        if (appId) {
          const app = this.appsList.find((obj) => obj.id === appId);
          this.setCurrentApp(app);
        } else if (this.appsList?.length > 0) {
          this.setCurrentApp(this.appsList[0]);
        }
      });
    });
  },
  methods: {
    ...mapActions("authorization", ["getUser"]),
    ...mapActions("apps", ["getApps", "setCurrentApp"]),
  },
  computed: {
    ...mapGetters("authorization", ["email"]),
    ...mapGetters("apps", ["appsList"]),
    pageNotFound() {
      return this.$route.name === "E404";
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  background: #f6f6f6;
}

.p-overflow-hidden {
  padding-right: 17px;

}

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper {
  width: 100%;
  display: flex;
  flex: 1 0;
}

.container {
  display: flex;
  flex: 4;
  // max-width: 1324px;
  // padding: 0 60px 60px 60px;
  width: 100%;
  overflow: auto;
  &__wrap {
    width: 100%;
    min-width: 600px;
    padding: 0 60px 60px 60px;
  }
}
</style>
