<template>
  <PageDescriptorSub
    :title="headerInfo.title"
    :content="headerInfo.content"
    @close="goBack"
  />
  <div class="content">
    <Title3 :content="createdAt" v-if="id" />
    <div class="content__item" v-if="id">
      <Title3 content="Id" class="content__item-title"></Title3>
      <SummaryCommentary
        >Identifier for this dialog. Used in API requests.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="id"
        :value="currentItem?.id"
        disabled
      />
    </div>
    <div class="content__item">
      <Title3 content="Name" class="content__item-title"></Title3>
      <SummaryCommentary
        >Name for this dialog. Used inside the platform for management
        purposes.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="name"
        :value="currentItem?.name"
        @input="handleUpdate($event, 'name')"
        placeholder="Write Dialog's Name"
      />
    </div>
    <div class="content__item">
      <Title3 content="CONTENT" class="content__item-title"></Title3>
      <SummaryCommentary
        >AI-sided content of this dialog. Contains questions and information
        that AI should ask or say.</SummaryCommentary
      >
      <ValueField
        class="content__item-form content__item-form--textarea"
        name="persona"
        :maxLenght="1000"
        :value="currentItem?.content"
        @input="handleUpdate($event, 'content')"
        placeholder="Write Dialog's Text Content"
        fieldType="textarea"
      />
    </div>
    <div class="content__item">
      <Title3
        content="MINIMAL TRUST LEVEL"
        class="content__item-title"
      ></Title3>
      <SummaryCommentary
        >Numeric value which describes how much the AI should trust the user in
        order to start using this sequence.</SummaryCommentary
      >
      <ValueField
        class="content__item-form content__item-form--w46"
        fieldType="input"
        name="min_trust_level"
        :value="currentItem?.min_trust_level"
        @input="handleUpdate($event, 'min_trust_level')"
      />
      <SummaryCommentary>Min 0 — Max 100</SummaryCommentary>
    </div>
    <div class="content__btns">
      <div class="content__btns-item">
        <Button
          v-if="id"
          @click="
            abId && hasAbId
              ? handleEdit(currentItem, abId)
              : abId && !hasAbId
              ? handleCreate(currentItem, abId)
              : handleEdit(currentItem)
          "
          content="Save"
          :disabled="!wasChanged"
        />
        <Button @click="handleCreate(currentItem)" content="Create" v-else />
      </div>
      <div class="content__btns-item">
        <Button @click="goBack" :content="discardChangesBtnText" />
      </div>
    </div>
  </div>
  <Modal
    :display="displayModal"
    title="Discard changes"
    content="You have unsaved changes on this page. Are you sure that you want to discard them?"
    btn="Yes, Discard"
    @onClose="discardChanges"
    @onConfirm="confirmChanges"
  />
</template>

<script>
import PageDescriptorSub from "@/components/frame/PageDescriptorSubPage";
import Title3 from "@/components/constructor/textual/Title3";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import ValueField from "@/components/constructor/graphical/ValueField";
import moment from "moment";
import Button from "@/components/constructor/graphical/Button";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Modal from "@/components/frame/Popup";
import _ from "lodash";

export default {
  components: {
    PageDescriptorSub,
    Title3,
    SummaryCommentary,
    ValueField,
    Button,
    Modal,
  },
  beforeRouteLeave(from, to, next) {
    if (this.wasChanged && !this.saveData) {
      this.confirm().then((result) => {
        if (result) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  created() {
    if (this.id) {
      this.getCurrentItem({
        appId: this.appId,
        itemId: this.id,
        abId: this.abId && this.hasAbId ? this.abId : null,
      }).then(() => {
        this.currentItemDefault = _.clone(this.currentItem);
      });
    } else {
      this.resetCurrentItem();
      this.currentItemDefault = _.clone(this.currentItem);
    }
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      id: this.$route.params.id ?? null,
      abId:
        this.$route.params.abId &&
        this.$route.params.abId !== "undefined" &&
        this.$route.params.abId !== "null"
          ? this.$route.params.abId
          : null,
      hasAbId: this.$route.params.hasAbId
        ? JSON.parse(this.$route.params.hasAbId)
        : null,
      displayModal: false,
      resolvePromise: null,
      currentItemDefault: null, //исходное значение
      saveData: false
    };
  },
  methods: {
    ...mapActions("dialogs", [
      "getCurrentItem",
      "updateCurrentItem",
      "resetCurrentItem",
      "createItem",
      "editItem",
    ]),
    confirm() {
      this.displayModal = true;
      return new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
    },
    discardChanges() {
      this.displayModal = false;
      this.resolvePromise(false);
    },
    confirmChanges() {
      this.resolvePromise(true);
    },
    goBack() {
      this.$router.push({ name: "dialogs" });
    },
    handleCreate(currentItem, abId) {
      const { id, name, content, min_trust_level } = currentItem;
      this.saveData = true;
      this.createItem({
        appId: this.appId,
        name: name,
        content: content,
        trustLevel: Number(min_trust_level),
        itemId: id,
        abId: abId,
      });
    },
    handleEdit({ id, name, content, min_trust_level }) {
      this.saveData = true;

      this.editItem({
        appId: this.appId,
        itemId: id,
        name: name,
        content: content,
        trustLevel: Number(min_trust_level),
        abId: this.abId,
      });
    },
    handleUpdate(val, field) {
      this.updateCurrentItem({
        field: field,
        value: val,
      });
    },
  },
  computed: {
    ...mapGetters("dialogs", ["currentItem"]),
    createdAt() {
      return `created on ${moment(this.currentItem?.created_at).format(
        "yyyy MMM DD hh:mm:ss"
      )}`;
    },
    headerInfo() {
      return this.id
        ? {
            title: "DIALOG / EDITOR",
            content:
              "Edit a dialog sequence, configure its content and advanced settings.",
          }
        : {
            title: "DIALOG / CREATOR",
            content:
              "Create a dialog sequence, configure its content and advanced settings.",
          };
    },
    wasChanged() {
      return !_.isEqual(this.currentItem, this.currentItemDefault);
    },
    discardChangesBtnText() {
      return this.wasChanged ? "Discard" : "Cancel";
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  &__item {
    & + .content__btns {
      margin-top: 60px;
    }
    &-title {
      margin-bottom: 12px;
    }
    &-form {
      width: 100%;
      &.content__item-form--textarea {
        :deep().field {
          height: 509px;
        }
      }
      &.content__item-form--w46 {
        :deep().field {
          min-width: 46px;
          width: 46px;
        }
      }
      :deep().field {
        width: 100%;
      }
    }
    &-limit {
      justify-content: right;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    &-item {
      & + & {
        margin-left: 12px;
      }
    }
  }
}
</style>
