<template>
  <PageDescriptorPrimary title="API KEYS" content="Token management." />
  <PageContent class="page-content">
    <div class="page-content__title">
      <Title2 content="App Token List" />
    </div>
    <div class="page-content__str">
      <OptionsSwitcher
        :options="optionsRows"
        :activeOption="activeRows.id"
        @onChange="handleChangeRows"
        name="rows"
      />
    </div>
    <div class="page-content__str">
      <ActionCallButton @click="handleCreate" content="+ Add Token" />
    </div>
    <Table
      :tableData="items"
      :tableColumns="columns"
      :loading="loading"
      :totalRecords="totalRecords"
      :activeRows="activeRows.value"
      :page="currentPage"
      paginationItems="API tokens"
      actions="remove"
      @onRemove="confirmRemove"
      @onSorting="sort"
      @onPageChange="handlePageChange"
    />
  </PageContent>
  <Modal
    :display="displayModal"
    :title="modalContent?.title"
    :content="modalContent?.content"
    :btn="modalContent?.btn"
    :closeIcon="activeModal !== 'tokenValue'"
    @onClose="closeModal"
    @onConfirm="modalContent?.method()"
  >
    <ValueField
      class="tokenInput"
      name="token"
      placeholder="Write Token Name"
      fieldType="input"
      @input="handleEditInput"
      v-if="activeModal === 'create'"
    ></ValueField>
    <div class="copy-token" v-else-if="activeModal === 'tokenValue'">
      <ValueField
        class="tokenInput"
        name="token"
        placeholder="Token Name"
        fieldType="input"
        :value="selectedToken"
        @input="handleEditInput"
        disabled
      ></ValueField>
      <img
        class="copy"
        src="@/assets/icons/Copy_42px_2x.png"
        alt="Copy_42px_2x"
        @click="copyToClipboard"
      />
    </div>
  </Modal>
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import Title2 from "@/components/constructor/textual/Title2";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import ActionCallButton from "@/components/constructor/graphical/ActionCallButton";
import Table from "@/components/constructor/graphical/Table";
import Modal from "@/components/frame/Popup";
import ValueField from "@/components/constructor/graphical/ValueField";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";
export default {
  components: {
    PageDescriptorPrimary,
    PageContent,
    Title2,
    OptionsSwitcher,
    ActionCallButton,
    Table,
    Modal,
    ValueField,
  },
  created() {
    this.getData({
      appId: this.appId,
      sort: this.sortObj,
      limit: this.activeRows.value,
      offset: this.currentPage * this.activeRows.value,
    });
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      columns: [
        { field: "last_used_at", header: "LAST USED AT", sortable: true },
        { field: "id", header: "ID" },
        { field: "name", header: "Name", sortable: true },
      ],
      displayModal: false,
      currentRowId: null,
      loading: false,
      sortObj: {
        sortField: "created_at",
        sortOrder: -1,
      },
      optionsRows: [
        {
          id: 0,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
        {
          id: 2,
          value: 30,
        },
      ],
      activeRows: {
        id: 0,
        value: 10,
      },
      currentPage: 0,
      editId: null,
      activeFilter: null,
      activeModal: null, //values: create, remove, tokenValue
      tokenCreateValue: null,
      selectedToken: null,
    };
  },
  methods: {
    ...mapActions("apiKeys", ["getData", "createItem", "deleteItem"]),
    showToken(data) {
      this.activeModal = "tokenValue";
      this.displayModal = true;
      this.selectedToken = data?.value;
    },
    handleCreate() {
      this.activeModal = "create";
      this.displayModal = true;
    },
    confirmRemove(id) {
      this.activeModal = "remove";
      this.currentRowId = id;
      this.displayModal = true;
    },
    confirmCreate() {
      this.loading = true;
      this.createItem({
        appId: this.appId,
        name: this.tokenCreateValue,
        webhookUrl: null,
      }).then((response) => {
        this.closeModal();
        this.getData({
          appId: this.appId,
          sort: this.sortObj,
          limit: this.activeRows.value,
          offset: this.currentPage * this.activeRows.value,
        }).finally(() => {
          this.loading = false;
        });
        this.showToken(response);
      });
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.selectedToken)
        .then(() => {
          Notification(descriptions.tokenCopied, types.success);
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },
    closeModal() {
      this.displayModal = false;
    },
    handleDelete() {
      this.loading = true;
      const { id } = this.currentRowId;
      this.deleteItem({ appId: this.appId, itemId: id }).then(() => {
        this.closeModal();
        this.getData({
          appId: this.appId,
          sort: this.sortObj,
          limit: this.activeRows.value,
          offset: this.currentPage * this.activeRows.value,
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    sort(data) {
      this.loading = true;
      this.sortObj = data;

      this.getData({
        appId: this.appId,
        sort: data,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      }).finally(() => {
        this.loading = false;
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loading = true;

      this.getData({
        appId: this.appId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeRows(data) {
      const prev = this.activeRows.value;
      this.activeRows = data;

      this.currentPage =
        Math.ceil((prev * this.currentPage + 1) / this.activeRows.value) - 1;
      this.getData({
        appId: this.appId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleEditInput(val) {
      this.tokenCreateValue = val;
    },
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapGetters("apiKeys", ["items", "totalRecords"]),
    modalContent() {
      let modalInfo = null;
      switch (this.activeModal) {
        case "create":
          modalInfo = {
            title: "Add token",
            content: "Define Name:",
            btn: "Create Token",
            method: this.confirmCreate,
          };
          break;
        case "remove":
          modalInfo = {
            title: "Remove?",
            content: "You sure that you want to remove chosen element?",
            btn: "Yes, remove",
            method: this.handleDelete,
          };
          break;
        case "tokenValue":
          modalInfo = {
            title: "Token value",
            content: "Copy and save the token value!",
            btn: "Got It",
            method: this.closeModal,
          };
          break;
      }
      return modalInfo;
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp.id;
      this.getData({
        appId: this.appId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  .copy {
    width: 42px;
    height: 42px;
    cursor: pointer;
  }
}
.tokenInput {
  width: 100%;
  :deep() input {
    width: 100%;
  }
}
.page-content {
  &__title {
    margin-bottom: 21px;
  }
  &__str {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & + & {
      margin-top: 21px;
    }
    &-group {
      display: flex;
      align-items: center;
    }
    &-text {
      margin-right: 12px;
    }
  }

  &__input {
    :deep() form {
      > .field {
        width: 100%;
      }
    }
  }
  &__button {
    margin-top: 12px;
  }
}
</style>
