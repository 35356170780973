<template>
  <div class="table" :class="newClass">
    <DataTable
      :lazy="true"
      :loading="loading"
      :value="tableData"
      showGridlines
      responsiveLayout="scroll"
      :totalRecords="totalRecords"
      :rows="activeRows"
      sortField="created_at"
      :sortOrder="-1"
      @sort="sort"
    >
      <Column
        header="Created At"
        sortable
        field="created_at"
        v-if="$route.name !== 'application'"
      >
        <template #body="slotProps">
          <div
            class="date"
            v-if="slotProps.data.created_at"
            @click="copyToClipboard(slotProps.data.created_at)"
          >
            {{ moment(slotProps.data.created_at) }}
          </div>
        </template>
      </Column>
      <Column header="Added At" field="added_at" v-else>
        <template #body="slotProps">
          <div
            class="date"
            v-if="slotProps.data.added_at"
            @click="copyToClipboard(slotProps.data.added_at)"
          >
            {{ moment(slotProps.data.added_at) }}
          </div>
        </template>
      </Column>
      <Column
        v-for="col of tableColumns"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :key="col.id"
        :class="{
          'voice-column': col.field === 'voice.name',
          lastUsed: col.field === 'last_used_at',
        }"
      >
        <!-- v-if="
            col.field === 'voice.name' ||
            col.field === 'state' ||
            col.field === 'id' ||
            col.field === 'last_used_at'
          " -->
        <template #body="slotProps">
          <div
            class="voice"
            v-if="col.field === 'voice.name'"
            @click="copyToClipboard(slotProps.data.voice.name)"
          >
            <span class="voice__text">{{ slotProps.data.voice.name }}</span>
            <button
              class="voice__btn"
              @click.stop="
                handlePlayAudio(slotProps.data.voice.id, slotProps.data.name)
              "
            />
          </div>
          <div class="state" v-else-if="col.field === 'state'">
            <OptionsDropdown
              newClass="dropdown--state"
              :content="slotProps.data.state"
              placeholder=""
            >
              <ul class="dropdown-list dropdown-list--state">
                <ListLink
                  class="dropdown-list__item"
                  v-for="item in optionsState"
                  :title="item.value"
                  :key="item.id"
                  @click="
                    $emit('state-edit', {
                      item: slotProps.data,
                      state: item.value,
                    })
                  "
                />
              </ul>
            </OptionsDropdown>
          </div>
          <div
            class="user-id"
            @click="copyToClipboard(slotProps.data.id)"
            v-else-if="col.field === 'id'"
          >
            <div
              class="user-id__content"
              :class="filteredClass(slotProps.data)"
            >
              {{ slotProps.data.id }}
            </div>
            <span class="user-id__prompt"> {{ slotProps.data.id }}</span>
          </div>
          <div
            v-else-if="col.field === 'last_used_at'"
            @click="copyToClipboard(slotProps.data.last_used_at)"
          >
            {{ moment(slotProps.data.last_used_at) }}
          </div>
          <div
            class="prompt-wrapper"
            @click="copyToClipboard(slotProps.data[col.field])"
            v-else
          >
            <div class="prompt-wrapper__content">
              {{ slotProps.data[col.field] }}
            </div>
            <span
              class="prompt"
              v-if="slotProps.data[col.field]?.length >= 12"
              >{{ slotProps.data[col.field] }}</span
            >
          </div>
        </template>
      </Column>
      <Column header="Actions" class="actions">
        <template #body="slotProps">
          <div class="btn-wrapper">
            <Button
              @click="handleEdit(slotProps.data)"
              class="btn btn-edit"
              content="Edit"
              v-if="actions === '*' || actions === 'edit'"
            />
            <Button
              @click="handleRemove(slotProps.data)"
              class="btn btn-remove"
              content="Remove"
              v-if="actions === '*' || actions === 'remove'"
            />
          </div>
        </template>
      </Column>
      <template #empty>
        <div class="empty">No data found.</div>
      </template>
    </DataTable>
    <div class="table__pagination">
      <div class="table__pagination-item">
        <Pagination
          :colRows="activeRows"
          :totalSize="totalRecords"
          :page="page"
          @page-change="pageChange"
        />
      </div>
      <div class="table__pagination-item">
        <SummaryCommentary>{{ infoString }}</SummaryCommentary>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "@/components/constructor/graphical/Button";
import Pagination from "@/components/constructor/graphical/Pagination";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/nova-vue/theme.css";
import "primeicons/primeicons.css";
import moment from "moment";
import OptionsDropdown from "@/components/constructor/textual/OptionsDropdown";
import ListLink from "@/components/constructor/textual/ListLink";
import Notification from "@/helpers/Notifications";
import { types } from "@/helpers/Notifications";

export default {
  name: "Table",
  components: {
    DataTable,
    Column,
    Pagination,
    SummaryCommentary,
    Button,
    OptionsDropdown,
    ListLink,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    activeRows: {
      type: Number,
      default: 0,
    },
    paginationItems: {
      type: String,
      default: "characters",
    },
    newClass: {
      type: String,
      default: null,
    },
    actions: {
      //edit, remove
      type: String,
      default: "*",
    },
    id: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    optionsState: [
      {
        id: 0,
        value: "Active",
        field: "state",
      },
      {
        id: 1,
        value: "Paused",
        field: "state",
      },
      {
        id: 2,
        value: "Closed",
        field: "state",
      },
    ],
  }),
  methods: {
    filteredClass(data) {
      return [
        {
          filtered: data.ab_id !== null && data.ab_id !== undefined,
        },
      ];
    },
    handleEdit(data) {
      this.$emit("onEdit", data);
    },
    handleRemove(data) {
      this.$emit("onRemove", data);
    },
    sort(e) {
      this.$emit("onSorting", e);
    },
    pageChange(e) {
      this.$emit("onPageChange", e);
    },
    moment(date) {
      return moment(date).format("yyyy MMM DD HH:mm:ss");
    },
    copyToClipboard(value) {
      if (document.getSelection().toString().trim().length === 0) {
        navigator.clipboard
          .writeText(value)
          .then(() => {
            Notification("Cell Content Copied", types.success);
          })
          .catch((err) => {
            console.log("Something went wrong", err);
          });
      }
    },
    handlePlayAudio(id, name) {
      this.$emit("play-audio", { id, name });
    },
  },
  computed: {
    amountShowStr() {
      if (this.tableData?.length >= this.activeRows) {
        return this.activeRows;
      } else {
        return this.tableData?.length;
      }
    },
    infoString() {
      const amount = this.totalRecords < 1 ? 0 : 1;
      let str = "";

      if (this.totalRecords <= 1) {
        str = `Showing ${this.paginationItems} ${amount}. Total ${this.totalRecords}.`;
      } else {
        str = `Showing ${this.paginationItems} ${
          this.activeRows * this.page + 1
        } to
          ${this.amountShowStr * (this.page + 1)}. Total: ${
          this.totalRecords
        }.`;
      }

      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  text-transform: uppercase;
}
:deep() .p-datatable-wrapper {
  overflow-x: unset !important;
}
:deep() .p-datatable-thead th.lastUsed {
  width: 234px;
  max-width: 234px;
}
:deep().empty {
  text-align: center;
}
:deep().dropdown__header {
  border: none;
}

.prompt-wrapper {
  position: relative;
  &__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover .prompt {
    opacity: 1;
    z-index: 1;
  }
  .prompt {
    position: absolute;
    top: -40px;
    left: 13px;
    padding: 6px 45px 6px 6px;
    background: rgba($color: #000000, $alpha: 0.75);
    color: $PureWhite;
    white-space: nowrap;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.5s ease;

    &::before {
      content: "";
      position: absolute;
      left: 58px;
      bottom: -16px;
      border: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.75);
      font-family: "RobotoMono";
      font-size: 12px;
      line-height: 16px;
      font-weight: 800;
      font-style: italic;
    }
  }
}

:deep() .p-datatable-tbody {
  cursor: pointer;
}
.dropdown-list {
  &.dropdown-list--state {
    padding-top: 15px;
    .dropdown-list__item:first-child {
      font-style: normal;
      &::before {
        content: "▶";
        display: inline-block;
        margin-right: 7px;
      }
    }
    .dropdown-list__item:last-child {
      &::before {
        content: "x";
        display: inline-block;
        text-transform: lowercase;
        margin-right: 5px;
        font-style: italic;
      }
    }
    .dropdown-list__item {
      height: 45px;
      font-style: normal;
      &:hover {
        font-style: italic;
      }
    }
  }
  .dropdown-list__item {
    padding-left: 21px;
    align-items: center;
  }
}
.table {
  margin-top: 20px;
  &__pagination {
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.table--voice {
    :deep() .p-datatable-thead {
      tr {
        th {
          &:nth-child(4) {
            max-width: 242px;
            width: 242px;
          }
        }
      }
    }
  }
  &.table--dialogs {
    :deep() .p-datatable-thead {
      tr {
        th {
          &:first-child {
            max-width: 235px;
            width: 235px;
          }
          &:nth-child(3) {
            max-width: 390px;
            width: 390px;
          }
        }
      }
    }
  }
  &.table--abtest {
    :deep() .p-datatable-thead {
      tr {
        th {
          &:nth-child(4) {
            max-width: 242px;
            width: 242px;
          }
          &:last-child {
            max-width: 311px;
            width: 311px;
          }
        }
      }
    }
  }
  &.table--settings {
    :deep() .p-datatable-thead {
      tr {
        th {
          &:nth-child(3) {
            max-width: 390px;
            width: 390px;
          }
          &:last-child {
            max-width: 311px;
            width: 311px;
          }
        }
      }
    }
  }
}
.voice {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    font-family: "RobotoMono";
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
  }

  &__btn {
    width: 40px;
    height: 40px;
    background: url("../../../assets/icons/Audio_40px_2x.png") center / contain
      no-repeat;
  }
}

:deep() .p-datatable-thead {
  th {
    border-color: $TableGrey !important;
    padding: 0 0px 0 19px !important;
    max-width: 149px;
    width: 149px;
    &:first-child {
      max-width: 234px;
      width: 234px;
    }

    &:last-child {
      max-width: 243px;
      width: 243px;
    }
  }
  .p-column-title {
    font-family: "RobotoMono";
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
    font-weight: 400;
    text-transform: uppercase;
  }
}
:deep() .p-datatable,
:deep() .p-paginator {
  font-family: "RobotoMono";
}
:deep() .p-datatable-tbody {
  tr {
    background: transparent !important;
    &:hover {
      background: #b3b3b3;
    }
    td {
      height: 100%;
      max-width: 149px;
      width: 149px;
      font-family: "RobotoMono";
      font-size: 16px;
      line-height: 21px;
      font-style: italic;
      border-color: $TableGrey !important;
      padding: 0 19px !important;
      &.voice-column {
        padding: 0 0 0 19px !important;
      }
      &:hover .user-id .user-id__prompt {
        opacity: 1;
        z-index: 1;
      }
      .user-id {
        position: relative;

        .user-id__content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .user-id__prompt {
          position: absolute;
          top: -40px;
          left: 13px;
          padding: 6px 45px 6px 6px;
          background: rgba($color: #000000, $alpha: 0.75);
          color: $PureWhite;
          white-space: nowrap;
          opacity: 0;
          z-index: -1;
          transition: opacity 0.5s ease;
        }
        .user-id__prompt::before {
          content: "";
          position: absolute;
          left: 58px;
          bottom: -16px;
          border: 8px solid transparent;
          border-top: 8px solid rgba(0, 0, 0, 0.75);
          font-family: "RobotoMono";
          font-size: 12px;
          line-height: 16px;
          font-weight: 800;
          font-style: italic;
        }
      }

      &.actions {
        .btn-wrapper {
          text-align: left !important;
          display: flex;
          align-items: center;
          gap: 20px;
          .btn {
            position: relative;
            &::after {
              content: "";
              width: 0;
              height: 100%;
              border-bottom: 1px solid currentColor;
              position: absolute;
              left: 0;
              bottom: 10px;
              transition: width 0.5s;
            }
            &:hover {
              &::after {
                width: 100%;
              }
            }
          }
          > button {
            min-width: auto;
            background: transparent;
            font-weight: 400;
          }
          .btn-edit {
            color: $AccentuatingMagenta;
            margin-left: 0;
            text-transform: capitalize;
          }
          .btn-remove {
            color: $Red;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
:deep() .p-datatable .p-sortable-column.p-highlight {
  background-color: transparent;
  color: #333333;
}
:deep() .p-column-header-content {
  display: flex;
  justify-content: space-between;

  .p-sortable-column-icon {
    display: block;
    width: 40px;
    height: 40px;
    background: url(../../../assets/icons/Sorting_Inactive_40px_2x.png) center /
      contain no-repeat;
  }
  .p-sortable-column-icon.pi-sort-amount-up-alt {
    background-image: url(../../../assets/icons/Sorting_Ascending_40px_2x.png);
  }
  .p-sortable-column-icon.pi-sort-amount-down {
    background-image: url(../../../assets/icons/Sorting_Descending_40px_2x.png);
  }
  .p-sortable-column-icon::before {
    content: "";
  }
}
</style>
