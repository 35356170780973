<template>
  <PageDescriptorSub
    :title="headerInfo.title"
    :content="headerInfo.content"
    @close="goBack"
  />
  <div class="content">
    <Title3 :content="createdAt" v-if="id" />
    <div class="content__item" v-if="id">
      <Title3 content="Id" class="content__item-title"></Title3>
      <SummaryCommentary
        >Identifier for this dialog. Used in API requests.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="id"
        :value="currentItem?.id"
        disabled
      />
    </div>
    <div class="content__item">
      <Title3 content="NAME" class="content__item-title"></Title3>
      <SummaryCommentary
        >Name for this A/B Test. Used inside the platform for management
        purposes.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="name"
        placeholder="Write A/B Test's Name"
        :value="currentItem?.name"
        @input="handleUpdate($event, 'name')"
      />
    </div>
    <div class="content__item">
      <Title3 content="TYPE" class="content__item-title"></Title3>
      <SummaryCommentary
        >Assignment strategy for this A/B test.</SummaryCommentary
      >
      <OptionsSwitcher
        :options="optionsType"
        :activeOption="activeType"
        @onChange="handleChangeType"
        name="type"
      />
      <SummaryCommentary
        >Manual — users have to be assigned into the A/B Test manually using API
        requests.</SummaryCommentary
      >
    </div>
    <div class="content__item">
      <Title3 content="STATE" class="content__item-title"></Title3>
      <SummaryCommentary>Current A/B Test state.</SummaryCommentary>
      <OptionsSwitcher
        :options="optionsState"
        :activeOption="activeState"
        @onChange="handleChangeState"
        name="state"
      />
      <SummaryCommentary
        >Close the A/B Test to permanently release all users from
        it.</SummaryCommentary
      >
    </div>
    <div class="content__btns">
      <div class="content__btns-item">
        <Button
          v-if="id"
          @click="
            abId && hasAbId
              ? handleEdit(currentItem, abId)
              : abId && !hasAbId
              ? handleCreate(currentItem, abId)
              : handleEdit(currentItem)
          "
          content="Save"
          :disabled="!wasChanged"
        />
        <Button @click="handleCreate(currentItem)" content="Create" v-else />
      </div>
      <div class="content__btns-item">
        <Button @click="goBack" :content="discardChangesBtnText" />
      </div>
    </div>
  </div>
  <Modal
    :display="displayModal"
    title="Discard changes"
    content="You have unsaved changes on this page. Are you sure that you want to discard them?"
    btn="Yes, Discard"
    @onClose="discardChanges"
    @onConfirm="confirmChanges"
  />
</template>

<script>
import PageDescriptorSub from "@/components/frame/PageDescriptorSubPage";
import Title3 from "@/components/constructor/textual/Title3";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import ValueField from "@/components/constructor/graphical/ValueField";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import moment from "moment";
import Button from "@/components/constructor/graphical/Button";
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/frame/Popup";
import _ from "lodash";

export default {
  components: {
    PageDescriptorSub,
    Title3,
    SummaryCommentary,
    ValueField,
    OptionsSwitcher,
    Button,
    Modal,
  },
  beforeRouteLeave(from, to, next) {
    if (this.wasChanged && !this.saveData) {
      this.confirm().then((result) => {
        if (result) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  created() {
    if (this.id) {
      this.getItem({
        appId: this.appId,
        itemId: this.id,
      }).then((response) => {
        this.activeState = this.optionsState.find(
          (obj) => obj.value === response.state
        )?.id;
        this.activeType = this.optionsType.find(
          (obj) => obj.value === response.type
        )?.id;
        this.currentItemDefault = _.clone(this.currentItem);
      });
    } else {
      this.resetCurrentItem();
    }
    this.currentItemDefault = _.clone(this.currentItem);
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      id: this.$route.params.id ?? null,
      optionsState: [
        {
          id: 0,
          value: "Active",
          field: "state",
        },
        {
          id: 1,
          value: "Paused",
          field: "state",
        },
        {
          id: 2,
          value: "Closed",
          field: "state",
        },
      ],
      optionsType: [
        {
          id: 0,
          value: "Manual",
          field: "type",
        },
        {
          id: 1,
          value: "Fixed",
          field: "type",
        },
        {
          id: 2,
          value: "Percent",
          field: "type",
        },
      ],
      activeState: 0,
      activeType: 0,
      displayModal: false,
      resolvePromise: null,
      currentItemDefault: null, //исходное значение
    };
  },
  methods: {
    ...mapActions("tests", [
      "getItem",
      "createItem",
      "editItem",
      "updateCurrentItem",
      "resetCurrentItem",
    ]),
    confirm() {
      this.displayModal = true;
      return new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
    },
    discardChanges() {
      this.displayModal = false;
      this.resolvePromise(false);
    },
    confirmChanges() {
      this.resolvePromise(true);
    },
    goBack() {
      this.$router.push({ name: "abTests" });
    },
    handleChangeType(data) {
      this.activeType = data.id;
      this.updateCurrentItem({
        field: data.field,
        value:
          data.value === "Manual"
            ? "Manual"
            : data.value === "Fixed"
            ? "Fixed"
            : "Percent",
      });
    },
    handleChangeState(data) {
      this.activeState = data.id;
      this.updateCurrentItem({
        field: data.field,
        value:
          data.value === "Active"
            ? "Active"
            : data.value === "Paused"
            ? "Paused"
            : "Closed",
      });
    },
    handleCreate({ name, type, state }) {
      this.saveData = true;
      this.createItem({
        appId: this.appId,
        name: name,
        type: type,
        state: state,
      });
    },
    handleEdit({ id, name, type, state }) {
      this.editItem({
        appId: this.appId,
        itemId: id,
        name: name,
        type: type,
        state: state,
      });
    },
    handleUpdate(val, field) {
      this.updateCurrentItem({
        field: field,
        value: val,
      });
    },
  },
  computed: {
    ...mapGetters("tests", ["currentItem"]),
    createdAt() {
      return `created on ${moment(this.currentItem?.created_at).format(
        "yyyy MMM DD HH:mm:ss"
      )}`;
    },
    headerInfo() {
      return this.id
        ? {
            title: "A/B TEST / EDITOR",
            content: "Create an A/B test and configure its advanced settings.",
          }
        : {
            title: "A/B TEST / CREATOR",
            content: "Edit an A/B test and configure its advanced settings.",
          };
    },
    wasChanged() {
      return !_.isEqual(this.currentItem, this.currentItemDefault);
    },
    discardChangesBtnText() {
      return this.wasChanged ? "Discard" : "Cancel";
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  &__item {
    & + .content__btns {
      margin-top: 60px;
    }
    &-title {
      margin-bottom: 12px;
    }
    &-form {
      width: 100%;
      &.content__item-form--textarea {
        :deep().field {
          height: 509px;
        }
      }
      &.content__item-form--w46 {
        :deep().field {
          min-width: 46px;
          width: 46px;
        }
      }
      :deep().field {
        width: 100%;
      }
    }
    &-limit {
      justify-content: right;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    &-item {
      & + & {
        margin-left: 12px;
      }
    }
  }
}
</style>
