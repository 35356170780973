<template>
  <div class="dropdown dropdown--multi" :class="{ active: isActive }">
    <div class="dropdown__header">
      <button class="dropdown__btn" @click="toggle">
        <img
          class="arrow"
          src="@/assets/icons/Arrow_Square_Down_42px_2x.png"
          alt="Arrow_Downwards"
        />
        <div class="dropdown__tags">
          <div
            class="dropdown__tags-item"
            v-for="item in tagsActive"
            :key="item.value"
          >
            <button class="tag">
              {{ item ? item.label : "" }}
              <span
                class="tag__close"
                @click.stop="removeActiveTag(item.value)"
              ></span>
            </button>
          </div>
          <div class="dropdown__tags-item">
            <input
              class="dropdown__tags-filter"
              type="text"
              :placeholder="placeholder"
              v-model="filterText"
            />
          </div>
        </div>
      </button>
    </div>

    <div class="dropdown__content">
      <ul class="dropdown__list">
        <li
          class="dropdown__list-item"
          v-for="item in filteredTags"
          :key="item.value"
        >
          <button
            class="tag unselected"
            @click="pushActiveTag(item)"
            :class="{ block: tagIsActive(item.value) }"
          >
            {{ item ? item.label : "" }}

          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionsMultiDropdown",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    tagsActive: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    document.addEventListener("click", (event) => {
      this.handleOutsideClick(event);
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", (event) => {
      this.handleOutsideClick(event);
    });
  },
  data: () => ({
    isActive: false,
    filterText: "",
  }),
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    handleClose() {
      this.isActive = false;
    },
    pushActiveTag(e) {
      if (!this.tagIsActive(e.value)) {
        this.$emit("pushTag", e);
      }
    },
    removeActiveTag(e) {
      this.$emit("removeTag", e);
    },
    handleOutsideClick(e) {
      if (!this.$el.contains(e.target)) {
        this.isActive = false;
      }
    },
  },
  computed: {
    filteredTags() {
      const searchText = this.filterText.toLowerCase();
      return this.tags.filter((item) =>
        item.label.toLowerCase().includes(searchText)
      );
    },
    tagIsActive() {
      return (id) => this.tagsActive.some((item) => item.value === id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  border: 1px solid $SoothingBlue;
  border-radius: 10px;
  font-family: "RobotoMono";
  font-size: 18px;
  line-height: 24px;
  color: $PureBlack;
  background: $PureWhite;
  &.block {
    background: $InactiveGrey;
    color: $PureWhite;
    font-style: italic;
  }

  &.unselected {
    padding-right: 12px;
  }
  &__close {
    margin-left: 17px;
    width: 30px;
    height: 30px;
    background: url(../../../assets/icons/Cross_30px_2x.png) center/contain
      no-repeat;
  }
}
.dropdown {
  position: relative;
  min-width: 364px;
  width: 100%;
  &.active {
    .dropdown__content {
      display: block;
    }
    .dropdown__btn {
      img {
        transform: rotate(180deg);
      }
    }
  }
  &.dropdown--multi {
    .dropdown__content {
      position: static;
    }
    .dropdown__btn {
      .text {
        font-style: italic;
        color: $InactiveGrey;
      }
    }
  }

  &__header {
    cursor: pointer;
    width: 100%;
    border: 5px solid $SoothingBlue;
    background: transparent;
    min-height: 42px;
    padding: 1px;
  }
  &__btn {
    user-select: none;
    padding: 0;
    color: #000000;
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: stretch;
    background: transparent;
    width: inherit;
    height: 100%;
    &-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .text {
      padding-left: 7px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
    .arrow {
      max-width: 32px;
      transition: transform 0.3s;
    }
  }

  &__tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: -3px;
    &-filter {
      width: 100%;
      height: 100%;
      min-width: 100px;
      background: transparent;
      color: $PureBlack;
      font-size: 16px;
      line-height: 21px;
      font-style: italic;
      padding-left: 7px;
    }
  }
  &__tags-item {
    padding: 3px;
    &:last-child {
      flex: 1 1 auto;
    }
  }
  &__content {
    padding-top: 20px;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
  }
  &__list-item {
    height: auto;
    padding: 3px;
  }
}
</style>
