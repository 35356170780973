import http from "@/api/api";
import { auth } from "@/api/api";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    appsList: [],
    currentApp: null,
  },
  getters: {
    appsList: (state) => state.appsList,
    currentApp: (state) => state.currentApp,
  },
  mutations: {
    getApps(state, payload) {
      state.appsList = payload;
    },
    setCurrentApp(state, payload) {
      state.currentApp = payload;
      localStorage.setItem("appId", state.currentApp.id);
    },
  },
  actions: {
    async getApps(store) {
      try {
        const response = await http.get("/api/v1/apps/", {
          headers: { ...auth() },
        });
        store.commit("getApps", response?.data);
        return response;
      } catch (e) {
        console.log("err", e);
      }
    },
    appCreate(store, payload) {
      http
        .post("/api/v1/apps/", { name: payload }, { headers: { ...auth() } })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log("err", error);
        });
    },
    setCurrentApp({ commit }, payload) {
      commit("setCurrentApp", payload);
    },
    async editApp({ commit }, { appId, name }) {
      try {
        const response = await http.patch(
          `/api/v1/apps/${appId}`,
          {
            app_id: appId,
            name,
          },
          { headers: { ...auth() } }
        );
        Notification(descriptions.applicationUpdate, types.success);
        return response;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
