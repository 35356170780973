<template>
  <div class="dropdown" :class="{ active: isActive }">
    <button class="dropbtn" @click="toggle">
      <div class="arrow-wrapper">
        <img
          class="arrow"
          src="@/assets/icons/Arrow_Square_Down_42px_2x.png"
          alt="Arrow_Downwards"
        />
      </div>
      <Summary>
        <span class="text">{{ content }}</span>
      </Summary>
      <slot name="special"></slot>
    </button>
    <div class="dropdown-content" @click="handleClose">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Summary from "@/components/constructor/textual/SummaryCommentary";

export default {
  name: "Toggle",
  components: {
    Summary,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.expanded) {
      document.addEventListener("click", (event) => {
        this.handleOutsideClick(event);
      });
    }
    this.isActive = this.expanded;
  },
  beforeUnmount() {
    if (!this.expanded) {
      document.removeEventListener("click", (event) => {
        this.handleOutsideClick(event);
      });
    }
  },
  data: () => ({
    isActive: false,
  }),
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    handleClose() {
      this.isActive = false;
    },
    handleOutsideClick(e) {
      if (!this.$el.contains(e.target)) {
        this.isActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  font-size: 18px;
  line-height: 24px;
  color: $PureBlack;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 42px;

  &.active {
    .dropdown-content {
      display: block;
    }
    .dropbtn {
      // background-color: #fff;
      img {
        transform: rotate(180deg);
      }
      .text {
        font-style: italic;
      }
    }
  }

  .dropbtn {
    cursor: pointer;
    padding: 0;
    color: #000000;
    font-size: 16px;
    border: none;
    display: flex;
    align-items: center;
    background: transparent;
    width: inherit;
    height: 100%;
    .arrow-wrapper {
      height: 78px;
      display: flex;
      align-items: center;
      .arrow {
        max-width: 42px;
        max-height: 42px;
        transition: transform 0.3s;
      }
    }

    .text {
      margin-left: 6px;
      font-family: "RobotoMono";
      font-size: 18px;
      font-style: unset;
      line-height: 24px;
      font-style: normal;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    // background-color: #fff;
    width: 100%;
    z-index: 1;
    padding: 0 0 21px;
  }
}
</style>
