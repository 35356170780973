<template>
  <PageDescriptorSub
    :title="headerInfo.title"
    :content="headerInfo.content"
    @close="goBack"
  />
  <div class="content">
    <Title3 :content="createdAt" v-if="id" />
    <div class="content__item" v-if="id">
      <Title3 content="Id" class="content__item-title"></Title3>
      <SummaryCommentary
        >Identifier for this question. Used in API requests.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="id"
        :value="currentItem?.id"
        disabled
      />
    </div>
    <div class="content__item">
      <Title3 content="Question" class="content__item-title"></Title3>
      <SummaryCommentary
        >The question, answers to which will be extracted.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="content"
        :value="currentItem.content"
        @input="handleUpdate($event, 'content')"
        placeholder="Write Question"
      />
    </div>
    <div class="content__btns">
      <div class="content__btns-item">
        <Button
          v-if="id"
          @click="
            abId && hasAbId
              ? handleEdit(currentItem, abId)
              : abId && !hasAbId
              ? handleCreate(currentItem, abId)
              : handleEdit(currentItem)
          "
          content="Save"
          :disabled="!wasChanged"
        />
        <Button @click="handleCreate(currentItem)" content="Create" v-else />
      </div>
      <div class="content__btns-item">
        <Button @click="goBack" :content="discardChangesBtnText" />
      </div>
    </div>
  </div>
  <Modal
    :display="displayModal"
    title="Discard changes"
    content="You have unsaved changes on this page. Are you sure that you want to discard them?"
    btn="Yes, Discard"
    @onClose="discardChanges"
    @onConfirm="confirmChanges"
  />
</template>

<script>
import PageDescriptorSub from "@/components/frame/PageDescriptorSubPage";
import Title3 from "@/components/constructor/textual/Title3";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import ValueField from "@/components/constructor/graphical/ValueField";
import Button from "@/components/constructor/graphical/Button";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import moment from "moment";
import Modal from "@/components/frame/Popup";
import _ from "lodash";

export default {
  components: {
    PageDescriptorSub,
    Title3,
    SummaryCommentary,
    ValueField,
    Button,
    Modal,
  },
  beforeRouteLeave(from, to, next) {
    if (this.wasChanged && !this.saveData) {
      this.confirm().then((result) => {
        if (result) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  created() {
    if (this.id) {
      this.getItem({
        appId: this.appId,
        itemId: this.id,
        abId: this.abId && this.hasAbId ? this.abId : null,
      }).then(() => {
        this.currentItemDefault = _.clone(this.currentItem);
      });
    } else {
      this.resetCurrentItem();
    }
    this.currentItemDefault = _.clone(this.currentItem);
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      id: this.$route.params.id ?? null,
      abId:
        this.$route.params.abId &&
        this.$route.params.abId !== "undefined" &&
        this.$route.params.abId !== "null"
          ? this.$route.params.abId
          : null,
      hasAbId: this.$route.params.hasAbId
        ? JSON.parse(this.$route.params.hasAbId)
        : null,
      displayModal: false,
      resolvePromise: null,
      currentItemDefault: null, //исходное значение
      saveData: false,
    };
  },
  methods: {
    ...mapActions("advancedSettings", [
      "getItem",
      "createItem",
      "editItem",
      "updateCurrentItem",
      "resetCurrentItem",
    ]),
    confirm() {
      this.displayModal = true;
      return new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
    },
    discardChanges() {
      this.displayModal = false;
      this.resolvePromise(false);
    },
    confirmChanges() {
      this.resolvePromise(true);
    },
    goBack() {
      this.$router.push({ name: "advancedSettings" });
    },
    handleCreate(currentItem, abId) {
      this.saveData = true;
      const { id, content } = currentItem;
      this.createItem({
        appId: this.appId,
        content: content,
        itemId: id,
        abId: abId,
      });
    },
    handleEdit({ id, content }) {
      this.saveData = true;
      this.editItem({
        appId: this.appId,
        itemId: id,
        content: content,
        abId: this.abId,
      });
    },
    handleUpdate(val, field) {
      this.updateCurrentItem({
        field: field,
        value: val,
      });
    },
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapGetters("advancedSettings", ["currentItem"]),
    headerInfo() {
      return this.id
        ? {
            title: "ANSWERS EXTRACTION / EDITOR",
            content:
              "Edit a question the answers to which will be periodically extracted from conversations.",
          }
        : {
            title: "ANSWERS EXTRACTION / CREATOR",
            content:
              "Create a question the answers to which will be periodically extracted from conversations.",
          };
    },
    createdAt() {
      return `created on ${moment(this.currentItem?.created_at).format(
        "yyyy MMM DD hh:mm:ss"
      )}`;
    },
    wasChanged() {
      return !_.isEqual(this.currentItem, this.currentItemDefault);
    },
    discardChangesBtnText() {
      return this.wasChanged ? "Discard" : "Cancel";
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp.id;
      this.getItem({
        appId: this.appId,
        itemId: this.id,
        abId: this.abId && this.hasAbId ? this.abId : null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  &__item {
    & + .content__btns {
      margin-top: 60px;
    }
    &-title {
      margin-bottom: 12px;
    }
    &-form {
      width: 100%;
      :deep().field {
        width: 100%;
      }
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    &-item {
      & + & {
        margin-left: 12px;
      }
    }
  }
}
</style>
