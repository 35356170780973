import http from "@/api/api";
import { auth } from "@/api/api";
import router from "@/router";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  name: "abTest",
  namespaced: true,
  state: {
    items: [],
    currentItem: {
      name: "",
      type: "Manual",
      state: "Active",
    },
    totalRecords: null,
  },
  getters: {
    items(state) {
      return state.items;
    },
    currentItem(state) {
      return state.currentItem;
    },
    totalRecords: (state) => Number(state.totalRecords),
  },
  mutations: {
    resetCurrentItem(state) {
      state.currentItem.name = "";
      state.currentItem.type = "Manual";
      state.currentItem.state = "Active";
    },
    getItems(state, payload) {
      state.items = payload;
    },
    getItem(state, payload) {
      state.currentItem = payload;
    },
    updateCurrentItem(state, { field, value }) {
      state.currentItem[field.toLowerCase()] = value;
    },
    getTotalRecords(state, payload) {
      state.totalRecords = payload;
    },
  },
  actions: {
    getItems(store, { appId, sort, limit, offset }) {
      return new Promise((resolve) => {
        http
          .get(`/api/v1/apps/${appId}/abs`, {
            headers: { ...auth() },
            params: {
              limit,
              offset,
              sort:
                sort && sort?.sortField
                  ? `${sort.sortField}_${
                      sort?.sortOrder === 1 ? "asc" : "desc"
                    }`
                  : null,
            },
          })
          .then((response) => {
            const payload = response.data;
            const total = response.headers["x-total-count"];
            store.commit("getItems", payload);
            store.commit("getTotalRecords", total);
            resolve(payload);
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
    },
    async getItem(store, { appId, itemId }) {
      try {
        const response = await http.get(`/api/v1/apps/${appId}/abs/${itemId}`, {
          headers: { ...auth() },
        });
        const payload = response.data;
        store.commit("getItem", payload);
        return payload;
      } catch (e) {
        console.log("err", e);
      }
    },
    createItem(store, { appId, name, type, state }) {
      http
        .post(
          `/api/v1/apps/${appId}/abs`,
          {
            name: name,
            type: type,
            state: state,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "abTests" });
          Notification(descriptions.testCreated, types.success);
        })
        .catch((error) => {
          console.log("err", error);
          Notification(descriptions.danger, types.danger);
        });
    },
    async editItem(store, { appId, itemId, name, type, state }) {
      try {
        const response = await http.patch(
          `/api/v1/apps/${appId}/abs/${itemId}`,
          {
            name: name,
            type: type,
            state: state,
          },
          { headers: { ...auth() } }
        );
        router.push({ name: "abTests" });
        Notification(descriptions.testUpdated, types.success);
        return;
      } catch (e) {
        console.log("err", e);
        Notification(descriptions.danger, types.danger);
      }
    },
    async deleteItem(store, { appId, itemId }) {
      try {
        const response = await http.delete(
          `/api/v1/apps/${appId}/abs/${itemId}`,
          {
            headers: { ...auth() },
          }
        );
        Notification(descriptions.testDeleted, types.success);
        return;
      } catch (e) {
        Notification(descriptions.danger, types.danger);
      }
    },
    updateCurrentItem(store, payload) {
      store.commit("updateCurrentItem", payload);
    },
    resetCurrentItem(store) {
      store.commit("resetCurrentItem");
    },
  },
};
