<template>
  <footer>
    <div class="email-wrapper">
      <a href="mailto:support@temporal.games" class="email"
        ><span class="bold">support</span>@temporal.games</a
      >
    </div>
    <div class="powered-wrapper">
      <a href="https://www.fluxcortex.com/" class="powered"
        >powered by <span class="bold">fluxcortex</span></a
      >
    </div>
    <div class="copyright-wrapper">
      <a href="https://temporal.games/" class="copyright">
        <span class="text">Ⓒ temporal games inc.</span>
        <div class="img-wrapper" @click="toMain">
          <img src="@/assets/logo/Icon_128.png" alt="logo" />
        </div>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    toMain() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
footer {
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #aaaaaa;
  .email,
  .powered,
  .copyright {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    .bold {
      font-weight: 700;
    }
  }
  .email-wrapper {
    width: 437px;
    text-align: center;
  }
  .copyright-wrapper {
    width: 437px;
    .copyright {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
      .img-wrapper {
        max-width: 78px;
        max-height: 78px;
        cursor: pointer;
      }
    }
  }
  .email, .powered, .copyright .text {
    position: relative;
    &::after {
      content: "";
      width: 0;
      height: 100%;
      border-bottom: 1px solid currentColor;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: width 0.4s;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}
</style>
