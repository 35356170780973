<template>
  <PageDescriptorPrimary
    title="Api Usage"
    content="A summary of API usage for your application."
    content2="Dates and times are UTC-based. Data may be delayed up to 5 minutes."
  />
  <h3 class="title">Daily Usage, US$</h3>
  <div class="panel">
    <DateSelector
      @date-change="handleDateChange"
      @month-change="setMonth"
      :date="date"
    ></DateSelector>
    <div class="filters">
      <div class="filters__item">
        <OptionsSwitcher
          :options="filters"
          :activeOption="activeFilter.id"
          @onChange="handleFilterChange"
          name="options"
        />
      </div>
      <div class="filters__item">
        <OptionsSwitcher
          :options="specialFilters"
          :activeOption="activeSpecialFilter.id"
          @onChange="handleSpecialFilterChange"
          name="options"
        />
      </div>
    </div>
  </div>

  <div class="chart">
    <vue-chart
      :id="Chart.id"
      :type="Chart.type"
      :data="Chart.data"
      :options="Chart.options"
      ref="chart"
    ></vue-chart>
  </div>
  <Progress
    :value="balanceInfo.credit"
    :total="tariff.monthly_credit"
    info="Promo Credit Left, US$:"
    v-if="balanceInfo && tariff"
  />
  <div class="requests-data">
    <div class="requests-data__title">
      <Title2 content="Request Breakdown (UTC), Latest" />
    </div>
    <div class="requests-data__flter" v-if="requestsDetailsFiltered.length > 0">
      <OptionsSwitcher
        :options="requestOptions"
        :activeOption="requestOptionActive.id"
        @onChange="handleRequestOptionChange"
        name="options"
      />
    </div>
    <div class="requests" v-if="requestsDetailsFiltered.length > 0">
      <SummaryCommentary
        class="request"
        v-for="item in requestsDetailsFiltered"
        :key="item.user_id"
      >
        <span class="date">{{ item.date }}</span>

        <span class="highlight"> API Method - {{ item.method + " " }} </span>
        <span class="accent"> {{ "$" + item.price }}</span>
      </SummaryCommentary>
    </div>
    <div class="no-requests" v-else>No Requests Registered</div>
  </div>
</template>

<script>
import Title2 from "@/components/constructor/textual/Title2";
import VueChart from "@j-t-mcc/vue3-chartjs";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import Progress from "@/components/constructor/graphical/ProgressBar";
import moment from "moment";
import DateSelector from "@/components/constructor/graphical/DateSelector";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    Title2,
    VueChart,
    OptionsSwitcher,
    SummaryCommentary,
    Progress,
    DateSelector,
    PageDescriptorPrimary,
  },
  mounted() {
    this.getChartData();
    this.getBillingData(this.currentApp?.id);
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      date: new Date(),
      specialFilters: [
        {
          id: 0,
          value: "Total",
        },
        {
          id: 1,
          value: "AI",
        },
        {
          id: 2,
          value: "Storage",
        },
        {
          id: 3,
          value: "TTS",
        },
      ],
      activeSpecialFilter: {
        id: 0,
        value: "Total",
      },
      filters: [
        {
          id: 0,
          value: "Daily",
        },
        {
          id: 1,
          value: "Cumulative",
        },
      ],
      activeFilter: {
        id: 0,
        value: "Daily",
      },
      Chart: {
        id: "bar",
        type: "bar",
        data: {
          // labels: this.xAxis,
          datasets: [
            {
              id: 1,
              label: "Price",
              backgroundColor: ["#3F4BF2"],
              data: [],
            },
            {
              id: 0,
              label: "Promo price",
              backgroundColor: ["#FA00D5"],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              align: "start",
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
                text: "Daily Usage, US$",
              },
              stacked: true,
            },
          },
        },
      },
      requestOptions: [
        {
          id: 0,
          value: 10,
        },
        {
          id: 1,
          value: 100,
        },
      ],
      requestOptionActive: {
        id: 0,
        value: 10,
      },
    };
  },
  methods: {
    ...mapActions("apiUsage", ["getData"]),
    ...mapActions("billing", { getBillingData: "getData" }),
    setMonth(direction) {
      if (direction === 1) {
        this.date = moment(this.date).add(1, "month").toDate();
      } else {
        this.date = moment(this.date).add(-1, "month").toDate();
      }
      this.getChartData();
      this.getBillingData(this.currentApp.id);
    },
    handleDateChange(date) {
      this.date = date;
      this.getChartData();
      this.getBillingData(this.currentApp.id);
    },
    handleSpecialFilterChange(filter) {
      this.activeSpecialFilter = filter;
      this.getChartData();
    },
    handleFilterChange(filter) {
      this.activeFilter = filter;
      if (filter.id === 1) {
        this.updateChartData(this.cumulative.price, this.cumulative.promoPrice);
      } else {
        this.updateChartData(this.chartData.price, this.chartData.promoPrice);
      }
      this.$refs.chart.update();
    },
    updateChartData(price, promoPrice) {
      const promoIndex = this.Chart.data.datasets.findIndex(
        (obj) => obj.id === 0
      );
      const priceIndex = this.Chart.data.datasets.findIndex(
        (obj) => obj.id === 1
      );
      this.Chart.data.datasets[priceIndex].data = price;
      this.Chart.data.datasets[promoIndex].data = promoPrice;
    },
    getChartData() {
      this.getData({
        appId: this.appId,
        type: this.activeSpecialFilter.value,
        month: moment(this.date).format("MM.yyyy"),
      }).then(() => {
        this.Chart.data.labels = this.xAxis;
        this.handleFilterChange(this.activeFilter);
      });
    },
    handleRequestOptionChange(option) {
      this.requestOptionActive = option;
    },
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapState("apiUsage", ["days"]),
    ...mapGetters("apiUsage", ["xAxis", "chartData", "details", "cumulative"]),
    ...mapGetters("billing", ["balanceInfo", 'tariff']),
    requestsDetailsFiltered() {
      let detailsList = this.details.map((item) => {
        return {
          date: moment(item.date).format("yyyy MMM DD HH:mm:ss"),
          is_free: item.is_free,
          method: item.method,
          price: item.price,
          user_id: item.user_id,
        };
      });

      return detailsList.filter((item, i) => {
        return i < this.requestOptionActive.value;
      });
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp?.id;
      this.getChartData();
      this.getBillingData(this.currentApp?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-left: 50px;
}
.no-requests {
  color: $PureBlack;
  line-height: 21px;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0;
}
.title {
  margin-top: 60px;
  font-weight: 700;
  font-style: italic;
  font-size: 18px;
}
.panel {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .month-picker {
    display: flex;
    align-items: center;
    :deep().p-calendar {
      .p-inputtext {
        width: 180px;
        text-align: center;
        outline: none;
        border: none;
        background: transparent;
        font-family: "RobotoMono";
        font-size: 28px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  .filters {
    display: flex;
    align-items: center;
    margin: 0 -10.5px;
    &__item {
      padding: 0 10.5px;
    }
  }
}

.chart {
  max-height: 400px;
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.requests-data {
  &__title {
    margin-bottom: 21px;
  }
  &__flter {
    margin-bottom: 12px;
  }
  :deep() .requests {
    margin-top: 10px;
    .request {
      display: block;
      padding: 5px 0;
      .date {
        text-transform: uppercase;
      }
    }
  }
}
</style>
