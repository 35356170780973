import http from "@/api/api";
import { auth } from "@/api/api";
import router from "@/router";
import Notification from "@/helpers/Notifications";
import { descriptions, types } from "@/helpers/Notifications";

export default {
  namespaced: true,
  state: {
    items: [],
    currentItem: {
      id: null,
      externalId: null,
      abId: null,
      name: null,
      created_at: null,
      info: null,
      gender: null,
    },
    qas: [],

    totalRecords: null,
  },
  getters: {
    items(state) {
      return state.items;
    },
    qas(state) {
      return state.qas;
    },
    currentItem(state) {
      return state.currentItem;
    },
    totalRecords: (state) => Number(state.totalRecords),
  },
  mutations: {
    resetCurrentItem(state) {
      state.currentItem.id = null;
      state.currentItem.externalId = null;
      state.currentItem.abId = null;
      state.currentItem.name = null;
      state.currentItem.info = null;
      state.currentItem.gender = null;
    },
    getQas(state, payload) {
      state.qas = payload.map((item) => {
        return {
          question: item.question,
          answer: item.answer,
        };
      });
    },
    getItems(state, payload) {
      state.items = payload;
    },
    getCurrentItem(state, payload) {
      state.currentItem = {
        id: payload.id,
        externalId: payload.external_id,
        abId: payload.ab_id,
        name: payload.name,
        info: payload.info,
        gender: payload.gender,
        created_at: payload.created_at,
      };
    },
    updateCurrentItem(state, { field, value }) {
      state.currentItem[field] = value;
    },
    getTotalRecords(state, payload) {
      state.totalRecords = Number(payload);
    },
  },
  actions: {
    async getQas(store) {
      try {
        const response = await http.get(
          `/api/v1/apps/${appId}/players/${itemId}`,
          {
            headers: { ...auth() },
          }
        );
        store.commit("getApps", response?.data);
        return response;
      } catch (e) {
        console.log("err", e);
      }
    },
    getItems(store, { appId, abId, sort, limit, offset, query }) {
      return new Promise((resolve) => {
        http
          .get(`/api/v1/apps/${appId}/players`, {
            headers: { ...auth() },
            params: {
              limit,
              offset,
              abId,
              sort:
                sort && sort?.sortField
                  ? `${sort.sortField}_${
                      sort?.sortOrder === 1 ? "asc" : "desc"
                    }`
                  : null,
              name: query,
            },
          })
          .then((response) => {
            const payload = response.data;
            const total = response.headers["x-total-count"];
            store.commit("getItems", payload);
            store.commit("getTotalRecords", total);
            resolve(payload);
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
    },
    editItem(store, { appId, itemId, name, info, gender, abId }) {
      http
        .patch(
          `/api/v1/apps/${appId}/players/${itemId}`,
          {
            name,
            info,
            gender,
            ab_id: abId,
          },
          { headers: { ...auth() } }
        )
        .then(() => {
          router.push({ name: "users" });
          Notification(descriptions.userUpdated, types.success);
        })
        .catch((error) => {
          const payload = error.response.data.detail;
          store.commit("handleErrors", payload);
          Notification(descriptions.danger, types.danger);
        });
    },
    getCurrentItem(store, { appId, itemId, abId }) {
      return new Promise((resolve) => {
        http
          .get(
            `/api/v1/apps/${appId}/players/${itemId}${abId ? `/${abId}` : ""}`,
            {
              headers: { ...auth() },
            }
          )
          .then((response) => {
            const payload = response.data;
            store.commit("getCurrentItem", payload);
            resolve(payload);
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
    },
    updateCurrentItem(store, payload) {
      store.commit("updateCurrentItem", payload);
    },
    resetCurrentItem(store) {
      store.commit("resetCurrentItem");
    },
  },
};
