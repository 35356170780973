<template>
  <PageDescriptorPrimary
    title="DIALOGS"
    content="Create and manage dialog sequences. Each sequence can be applied to the specified character, who will try to naturally unwrap specified sequence given the occasion."
  />
  <PageContent class="page-content">
    <div class="page-content__title">
      <Title2 content="Dialog Sequences List" />
    </div>
    <div class="page-content__str">
      <OptionsSwitcher
        :options="optionsRows"
        :activeOption="activeRows.id"
        @onChange="handleChangeRows"
        name="rows"
      />
      <div class="page-content__str-group">
        <SummaryCommentary class="page-content__str-text">
          A/B:
        </SummaryCommentary>
        <OptionsSwitcher
          :options="tests"
          :activeOption="activeFilterId"
          @onChange="handleChangeFilter"
          name="options"
          resetOption
        />
      </div>
    </div>
    <div class="page-content__str">
      <ActionCallButton @click="handleCreate" content="+ Add Dialog Sequence" />
      <div class="page-content__str-group">
        <SummaryCommentary class="page-content__str-text">
          Filter by NAME:
        </SummaryCommentary>
        <ValueField
          name="filterNames"
          placeholder="Name"
          fieldType="input"
          @input="handleSearch"
        />
      </div>
    </div>
    <Table
      :tableData="items"
      :tableColumns="columns"
      :loading="loading"
      :totalRecords="totalRecords"
      :activeRows="activeRows.value"
      :page="currentPage"
      class="table--dialogs"
      paginationItems="dialogs"
      @onEdit="handleEdit"
      @onRemove="confirm"
      @onSorting="sort"
      @onPageChange="handlePageChange"
    />
  </PageContent>
  <Modal
    :display="displayModal"
    title="Remove?"
    content="You sure that you want to remove chosen element?"
    btn="YES, REMOVE"
    @onClose="closeModal"
    @onConfirm="handleDelete"
  />
</template>

<script>
import PageDescriptorPrimary from "@/components/frame/PageDescriptorPrimary";
import PageContent from "@/components/frame/PageContent";
import Title2 from "@/components/constructor/textual/Title2";
import OptionsSwitcher from "@/components/constructor/graphical/OptionsSwitcher";
import ActionCallButton from "@/components/constructor/graphical/ActionCallButton";
import ValueField from "@/components/constructor/graphical/ValueField";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import Table from "@/components/constructor/graphical/Table";
import Modal from "@/components/frame/Popup";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    PageDescriptorPrimary,
    OptionsSwitcher,
    ActionCallButton,
    Title2,
    ValueField,
    SummaryCommentary,
    PageContent,
    Table,
    Modal,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      columns: [
        { field: "id", header: "ID" },
        { field: "name", header: "Name", sortable: true },
      ],
      displayModal: false,
      currentRowId: null,
      //dataTable
      loading: false,
      tests: null,
      sortObj: {
        sortField: "created_at",
        sortOrder: -1,
      },
      optionsRows: [
        {
          id: 0,
          value: 10,
        },
        {
          id: 1,
          value: 20,
        },
        {
          id: 2,
          value: 30,
        },
      ],
      activeRows: {
        id: 0,
        value: 10,
      },
      currentPage: 0,
      activeFilter: null,
      searchValue: null,
    };
  },
  methods: {
    ...mapActions("dialogs", ["getItems", "deleteItem"]),
    ...mapActions("tests", { getFilters: "getItems" }),
    getData() {
      this.loading = true;
      this.getItems({
        appId: this.appId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      }).finally(() => {
        this.loading = false;
      });
      this.getFilters({ appId: localStorage.getItem("appId") }).then(
        (response) => {
          let filteredArr = response.filter((item) => {
            return item.state !== "Closed";
          });

          this.tests = filteredArr.map((item) => {
            return {
              id: item.id,
              value: item.name,
              state: item.state,
              type: item.type,
            };
          });
        }
      );
    },
    handleCreate() {
      this.$router.push({ name: "dialogsCreate" });
    },
    handleEdit(data) {
      const { id, ab_id } = data;
      this.$router.push({
        name: "dialogsEdit",
        params: {
          id: id,
          abId: this.activeFilter,
          hasAbId: ab_id && ab_id === this.activeFilter,
        },
      });
    },
    confirm(id) {
      this.currentRowId = id;
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    handleDelete() {
      this.loading = true;
      const { id, ab_id } = this.currentRowId;
      this.closeModal();
      this.deleteItem({ appId: this.appId, itemId: id, abId: ab_id }).then(
        () => {
          this.getItems({
            appId: this.appId,
            abId: this.activeFilter,
            sort: this.sortObj,
            limit: this.activeRows.value,
            offset: this.currentPage * this.activeRows.value,
            query: this.searchValue,
          }).finally(() => {
            this.loading = false;
          });
        }
      );
    },
    sort(data) {
      this.loading = true;
      this.sortObj = data;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: data,
        query: this.searchValue,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeFilter(data) {
      this.activeFilter = data;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilterId,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loading = true;

      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeRows(data) {
      const prev = this.activeRows.value;
      this.activeRows = data;

      this.currentPage =
        Math.ceil((prev * this.currentPage + 1) / this.activeRows.value) - 1;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query: this.searchValue,
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSearch: _.debounce(function (query) {
      this.searchValue = query;
      this.getItems({
        appId: this.appId,
        abId: this.activeFilter,
        sort: this.sortObj,
        limit: this.activeRows.value,
        offset: this.currentPage * this.activeRows.value,
        query,
      }).finally(() => {
        this.loading = false;
      });
    }, 1000),
  },
  computed: {
    ...mapGetters("apps", ["currentApp"]),
    ...mapGetters("dialogs", ["items", "totalRecords"]),
    activeFilterId() {
      return this.activeFilter ? this.activeFilter.id : null;
    },
  },
  watch: {
    currentApp() {
      this.appId = this.currentApp.id;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title2 {
  margin-bottom: 20px;
}
.page-content {
  &__title {
    margin-bottom: 21px;
  }
  &__str {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & + & {
      margin-top: 21px;
    }
    &-group {
      display: flex;
      align-items: center;
    }
    &-text {
      margin-right: 12px;
    }
  }

  &__input {
    :deep() form {
      > .field {
        width: 100%;
      }
    }
  }
  &__button {
    margin-top: 12px;
  }
}
</style>
