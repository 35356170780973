<template>
  <PageDescriptorSub
    title="USER / EDITOR"
    content="Edit the user registered in the App."
    @close="goBack"
  />

  <div class="content">
    <Title3 :content="createdAt" />
    <div class="content__item">
      <Title3 content="Id" class="content__item-title"></Title3>
      <SummaryCommentary
        >Identifier for this user. Used in API requests.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="id"
        :value="currentItem?.id"
        disabled
      />
    </div>
    <div class="content__item">
      <Title3 content="EXTERNAL ID" class="content__item-title"></Title3>
      <SummaryCommentary
        >External identifier for this user. Used in API
        requests.</SummaryCommentary
      >
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="externalId"
        :value="currentItem?.externalId"
        disabled
      />
    </div>
    <div class="content__item">
      <Title3 content="Name" class="content__item-title"></Title3>
      <SummaryCommentary>User-chosen name within the App.</SummaryCommentary>
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="name"
        :value="currentItem.name"
        @input="handleUpdate($event, 'name')"
        placeholder="Write User Name"
      />
    </div>
    <div class="content__item">
      <Title3 content="GENDER" class="content__item-title"></Title3>
      <SummaryCommentary>User-defined gender.</SummaryCommentary>
      <ValueField
        class="content__item-form"
        fieldType="input"
        name="gender"
        :value="currentItem.gender"
        @input="handleUpdate($event, 'gender')"
        placeholder="Write User Gender"
      />
    </div>
    <div class="content__item">
      <Title3 content="INFO" class="content__item-title"></Title3>
      <SummaryCommentary>Custom information on the user.</SummaryCommentary>
      <ValueField
        class="content__item-form content__item-form--textarea"
        name="info"
        :value="currentItem?.info"
        @input="handleUpdate($event, 'info')"
        placeholder="Write Custom User Information"
        fieldType="textarea"
        :maxLenght="255"
      />
    </div>
    <div class="content__item">
      <Title3 content="A/B TEST" class="content__item-title"></Title3>
      <SummaryCommentary
        >Sets one of available A/B Tests if other than None is
        chosen.</SummaryCommentary
      >
      <OptionsDropdown :content="abTestSelected?.name" placeholder="None">
        <ul class="dropdown-list">
          <ListLink
            class="dropdown-list__item"
            v-for="item in testsFiltered"
            :title="item.name"
            :key="item.id"
            @click="setActiveTest(item)"
          />
        </ul>
      </OptionsDropdown>
    </div>
    <div class="content__item">
      <Title3 content="QAS" class="content__item-title"></Title3>
      <Toggle
        class="toggle"
        content="User's answers on the App's questionnaire."
      >
        <SummaryCommentary>
          <ul class="questions">
            <li
              class="questions__item"
              v-for="item in qas"
              :key="item.question"
            >
              <p class="questions__item-text questions__item-text--bold">
                {{ "Q: " + item.question }}
              </p>
              <p class="questions__item-text">{{ "A: " + item.answer }}</p>
            </li>
          </ul>
        </SummaryCommentary>
      </Toggle>
    </div>
    <div class="content__btns">
      <div class="content__btns-item">
        <Button
          v-if="id"
          @click="
            abId && hasAbId
              ? handleEdit(currentItem, abId)
              : abId && !hasAbId
              ? handleCreate(currentItem, abId)
              : handleEdit(currentItem)
          "
          content="Save"
          :disabled="!wasChanged"
        />
        <Button @click="handleCreate(currentItem)" content="Create" v-else />
      </div>
      <div class="content__btns-item">
        <Button @click="goBack" :content="discardChangesBtnText" />
      </div>
    </div>
  </div>
  <Modal
    :display="displayModal"
    title="Discard changes"
    content="You have unsaved changes on this page. Are you sure that you want to discard them?"
    btn="Yes, Discard"
    @onClose="discardChanges"
    @onConfirm="confirmChanges"
  />
</template>

<script>
import Button from "@/components/constructor/graphical/Button";
import Title3 from "@/components/constructor/textual/Title3";
import OptionsDropdown from "@/components/constructor/textual/OptionsDropdown";
import ValueField from "@/components/constructor/graphical/ValueField";
import SummaryCommentary from "@/components/constructor/textual/SummaryCommentary";
import PageDescriptorSub from "@/components/frame/PageDescriptorSubPage";
import Toggle from "@/components/constructor/textual/Toggle";
import ListLink from "@/components/constructor/textual/ListLink";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/frame/Popup";
import _ from "lodash";

export default {
  name: "UsersEdit",
  components: {
    Button,
    Title3,
    Toggle,
    OptionsDropdown,
    ValueField,
    SummaryCommentary,
    PageDescriptorSub,
    ListLink,
    Modal,
  },
  beforeRouteLeave(from, to, next) {
    if (this.wasChanged && !this.saveData) {
      this.confirm().then((result) => {
        if (result) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  created() {
    if (this.id) {
      this.getAbTests({ appId: this.appId }).then(() => {
        this.getCurrentItem({
          appId: this.appId,
          itemId: this.id,
          abId: this.abId && this.hasAbId ? this.abId : null,
        }).then(() => {
          const test = this.items?.find(
            (obj) => obj.id === this.currentItem.abId
          );
          this.abTestSelected = { ...test };
          this.currentItemDefault = _.clone(this.currentItem);
        });
      });
    } else {
      this.resetCurrentItem();
    }
    this.currentItemDefault = _.clone(this.currentItem);
  },
  data() {
    return {
      appId: localStorage.getItem("appId"),
      id: this.$route.params.id ?? null,
      abId:
        this.$route.params.abId &&
        this.$route.params.abId !== "undefined" &&
        this.$route.params.abId !== "null"
          ? this.$route.params.abId
          : null,
      hasAbId: this.$route.params.hasAbId
        ? JSON.parse(this.$route.params.hasAbId)
        : null,
      abTestsList: [],
      abTestSelected: null,
      displayModal: false,
      resolvePromise: null,
      currentItemDefault: null, //исходное значение
      saveData: false,
    };
  },
  methods: {
    ...mapActions("users", [
      "getCurrentItem",
      "updateCurrentItem",
      "resetCurrentItem",
      "editItem",
    ]),
    ...mapActions("tests", { getAbTests: "getItems" }),
    setActiveTest(test) {
      this.abTestSelected = test;
    },
    confirm() {
      this.displayModal = true;
      return new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
    },
    discardChanges() {
      this.displayModal = false;
      this.resolvePromise(false);
    },
    confirmChanges() {
      this.resolvePromise(true);
    },
    goBack() {
      this.$router.push({ name: "users" });
    },
    handleEdit({ id, name, info, gender }) {
      this.saveData = true;
      this.editItem({
        appId: this.appId,
        itemId: id,
        name: name,
        info: info,
        gender: gender,
        abId: this.abTestSelected?.id,
      });
    },
    handleUpdate(val, field) {
      this.updateCurrentItem({
        field: field,
        value: val,
      });
    },
  },
  computed: {
    ...mapGetters("users", ["currentItem", "qas"]),
    ...mapGetters("tests", ["items"]),
    testsFiltered() {
      const none = {
        name: "None",
        id: null,
      };
      return [none, ...this.items];
    },
    createdAt() {
      return `created on ${moment(this.currentItem?.created_at).format(
        "yyyy MMM DD hh:mm:ss"
      )}`;
    },
    wasChanged() {
      return !_.isEqual(this.currentItem, this.currentItemDefault);
    },
    discardChangesBtnText() {
      return this.wasChanged ? "Discard" : "Cancel";
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-list {
  max-height: 200px;
  padding: 12px 0;
  overflow: auto;
  &__item {
    height: 46px;
    padding: 0 21px;
    transition: background-color 0.5s, color 0.5s;
    align-items: center;
    & + & {
      margin-top: 10px;
    }
    &:hover {
      background: #3f4bf2;
      color: #fff;
    }
  }
}
.questions {
  padding: 16px 0px 35px 0px;
  &__item {
    & + & {
      margin-top: 28px;
    }
  }

  &__item-text {
    font-family: "RobotoMono";
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
  }

  &__item-text--bold {
    font-weight: 700;
  }
}

.content {
  &__item {
    & + .content__btns {
      margin-top: 60px;
    }
    &-title {
      margin-bottom: 12px;
    }
    &-form {
      width: 100%;
      &.content__item-form--textarea {
        :deep().field {
          height: 90px;
        }
      }
      :deep().field {
        width: 100%;
      }
    }
    &-limit {
      justify-content: right;
    }
    &-group {
      display: flex;
      align-items: center;
      .content__item-form {
        flex: 1 1 auto;
      }
      & + & {
        margin-top: 11px;
      }
      .voice-img {
        margin-right: 12px;
      }
    }
    .content__item--textmr {
      margin-right: 68px;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    &-item {
      & + & {
        margin-left: 12px;
      }
    }
  }
}
</style>
